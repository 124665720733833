import { useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { ID } from '../../common/types';
import {
  selectRequestProperties,
  selectSort,
} from '../../filters/filtersSlice';
import { RequestProperty } from '../../filters/types';
import useSearch from '../../search/hooks/useSearch';
import { AdSort } from '../types';

const useRequestArgs = (categoryId: ID) => {
  const [likes] = useSearch();
  const properties = useAppSelector<RequestProperty[]>((state) =>
    selectRequestProperties(state, categoryId)
  );
  const sort = useAppSelector<AdSort>(selectSort);

  return useMemo(
    () => ({
      categories: [categoryId].filter(Boolean),
      likes,
      pre_last: 0,
      max: 24,
      sort,
      properties,
    }),
    [categoryId, likes, properties, sort]
  );
};

export default useRequestArgs;

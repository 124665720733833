import { createSlice, createAsyncThunk, Selector } from '@reduxjs/toolkit';
import { getAccessToken } from './../../utils/tokens';
import { RootState } from '../../app/store';
import api from '../../utils/api';
import { LoadingStatus } from './../common/types';
import { AdListItem } from '../ads/types';

export interface MyAdsState {
  list: AdListItem[];
  status: LoadingStatus;
}

const initialState: MyAdsState = {
  list: [],
  status: 'idle',
};

const fetchMyAds = async () => {
  const key = await getAccessToken();
  const response = await api.post(
    '/my_boards',
    {},
    {
      headers: { key },
    }
  );
  return response.data;
};

export const getMyAds = createAsyncThunk<AdListItem[]>(
  'ads/getMyAds',
  fetchMyAds
);

export const myAds = createSlice({
  name: 'myAds',
  initialState,
  reducers: {
    resetAds: (state) => {
      state.list = [];
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyAds.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getMyAds.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.list = payload;
    });

    builder.addCase(getMyAds.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { resetAds } = myAds.actions;

export const selectMyAdsState: Selector<RootState, MyAdsState> = (state) =>
  state.myAds;

export default myAds.reducer;

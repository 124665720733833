import { FC, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Category } from '../../../features/categories/types';
import Dropdown from '../../../features/common/Dropdown';
import { ID, MenuOption } from '../../../features/common/types';
import CategorySelectorItemContainer from './CategorySelectorItemContainer';
import styles from './CategorySelector.module.scss';

interface CategorySelectorItemProps {
  categories: Category[];
  onChange: (value: ID | null) => void;
}

const CategorySelectorItem: FC<CategorySelectorItemProps> = ({
  categories,
  onChange,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!categories.some((category) => category.ID.toString() === value)) {
      setValue('');
    }
  }, [categories, value]);

  const options: MenuOption[] = categories.map(({ title, ID }) => ({
    label: title,
    value: ID.toString(),
  }));

  if (isEmpty(categories)) {
    return null;
  }

  const handleChange = (value: string) => {
    onChange(value ? Number(value) : null);
    setValue(value);
  };

  return (
    <>
      <Dropdown
        labelClassName={styles.dropdownLabel}
        options={options}
        value={value}
        onChange={handleChange}
      />
      {value && (
        <CategorySelectorItemContainer
          onChange={onChange}
          categoryId={Number(value)}
        />
      )}
    </>
  );
};

export default CategorySelectorItem;

import { FC, useState } from 'react';
import cn from 'classnames';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { getImgUrl } from '../../utils/api';
import styles from './Slider.module.scss';

interface AdDetailsSliderProps {
  images: number[];
}

const Slider: FC<AdDetailsSliderProps> = ({ images }) => {
  const [picIndex, setPicIndex] = useState<number>(0);

  const handleDotClick = (index: number) => {
    if (index >= 0 && index < images.length) {
      setPicIndex(index);
    }
  };

  return (
    <div id="slider" className={styles.slider}>
      <div className={styles.imgWrapper}>
        {picIndex > 0 ? (
          <div
            className={styles.prevBtn}
            onClick={() => handleDotClick(picIndex - 1)}
          >
            <FaChevronLeft />
          </div>
        ) : null}
        {picIndex < images.length - 1 ? (
          <div
            className={styles.nextBtn}
            onClick={() => handleDotClick(picIndex + 1)}
          >
            <FaChevronRight />
          </div>
        ) : null}

        {images.map((imgId, index) => (
          <div
            key={imgId}
            className={cn(styles.item, { [styles.active]: index === picIndex })}
          >
            <div
              className={styles.background}
              style={{ backgroundImage: `url(${getImgUrl(imgId)})` }}
            />
            <img src={getImgUrl(imgId)} alt="" className="img-fluid" />
          </div>
        ))}
      </div>
      <ul id="dots" className={cn('list-inline', styles.dots)}>
        {images.map((imgId, index) => (
          <li
            key={imgId}
            className={cn('list-inline-item', {
              [styles.dotsActive]: index === picIndex,
            })}
            onClick={() => handleDotClick(index)}
          >
            {index}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Slider;

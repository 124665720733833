import { FC } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectIsAuth } from '../features/user/userSlice';
import PreHeader from './PreHeader';

const PreHeaderContainer: FC = () => {
  const isAuth = useAppSelector<boolean>(selectIsAuth);
  return <PreHeader isAuth={isAuth} />;
};

export default PreHeaderContainer;

export const currencyIconsMap = new Map([
  ['RUB', <div className="currency_label">₽</div>],
  ['GBP', <div className="currency_label">£</div>],
  ['USD', <div className="currency_label">$</div>],
  ['TRY', <div className="currency_label">₺</div>],
  ['EUR', <div className="currency_label">€</div>],
]);

export const currencyIdStorageKey = 'CURRENCY_ID';

export const RUB_CODE = 643;

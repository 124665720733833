import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Ad } from '../../features/ads/types';
import {
  getAdDetails,
  resetAds,
  selectAdDetailsData,
  selectAdDetailsStatus,
} from '../../features/ads/adSlice';
import { AdDetails } from '.';
import { Currency } from '../../features/currency/types';
import { selectCurrency } from '../../features/currency/currencySlice';
import { LoadingStatus } from '../../features/common/types';
import NotFound from '../../features/NotFound';

const AdDetailsContainer: FC = () => {
  const { adSlug } = useParams();
  const dispatch = useAppDispatch();
  const data = useAppSelector<Ad | null>(selectAdDetailsData);
  const currency = useAppSelector<Currency | null>(selectCurrency);
  const status = useAppSelector<LoadingStatus>(selectAdDetailsStatus);

  useEffect(() => {
    if (adSlug) {
      dispatch(getAdDetails({ slug: adSlug }));
    }
    return () => {
      dispatch(resetAds());
    };
  }, [dispatch, adSlug]);

  if (status === 'failed') {
    return <NotFound />;
  }

  const [categoryId] = data?.categories || [];
  return (
    data && (
      <AdDetails
        categoryId={categoryId}
        data={data}
        currencyCode={currency?.code}
      />
    )
  );
};

export default AdDetailsContainer;

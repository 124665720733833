import { FC } from 'react';
import AdCardContainer from '../../features/ads/components/AdCardContainer';
import AdsContainer from '../../features/ads/components/AdsContainer';
import MultiCardsPlaceholder from '../../features/ads/components/MultiCardsPlaceholder';
import NoDataMessage from '../../features/ads/components/NoDataMessage';
import SortDropdown from '../../features/ads/components/SortDropdown';

interface HomeAdsProps {
  title: string;
}

const HomeAds: FC<HomeAdsProps> = ({ title }) => {
  return (
    <AdsContainer categoryId={0}>
      {({ sort, handleSort, data, status }) => (
        <article>
          <div className="container">
            <div className="row">
              <div className="col-6-sm">
                <h2>{title}</h2>
              </div>
              <div className="col-6-sm right">
                {sort !== undefined && (
                  <SortDropdown value={sort} onSelect={handleSort} />
                )}
              </div>
            </div>

            <div className="padding_10"></div>
            <div className="row">
              {status === 'success' && data.length === 0 && (
                <div className="coll-12">
                  <NoDataMessage />
                </div>
              )}
              {data.map((item) => (
                <div key={item.ID} className="col-4">
                  <AdCardContainer ad={item} />
                </div>
              ))}
              {status === 'loading' && <MultiCardsPlaceholder columns={3} />}
            </div>
          </div>
        </article>
      )}
    </AdsContainer>
  );
};

export default HomeAds;

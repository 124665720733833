const translations = {
  ru: {
    'ad_details.lable.go_to_src': 'Перейти к источнику',
    'ad_details.title.description': 'Описание',
    'ad_details.title.properties': 'Характеристики',
    'auth.do_you_have_an_account': 'Уже есть учетная запись?',
    'auth.label.email': 'Электронная почта',
    'auth.label.password': 'Пароль',
    'auth.label.password_confirm': 'Подтверждение пароля',
    'auth.label.user_name': 'Имя пользователя',
    'auth.login_form_title': 'Войдите в свой аккаунт',
    'auth.sign_up_form_title': 'Регистрация пользователя',
    'breadcrumbs.home': 'Главная',
    'category.filter.subcategories_title': 'Уточнить подкатегорию',
    'category.title.search_result':
      'Результаты по запросу {search} в категории {title}',
    'create_ad.label.ad_description': 'Описание объявления',
    'create_ad.label.ad_tittle': 'Hазвание объявления',
    'create_ad.label.enter_ad_description': 'Введите описание объявления',
    'create_ad.label.enter_ad_tittle': 'Введите название объявления',
    'create_ad.label.price': 'Цена',
    'create_ad.label.select_category': 'Выберите категорию',
    'create_ad.label.specify_price': 'Укажите цену',
    'create_ad.label.specify_properties': 'Укажите свойства',
    'create_ad.label.upload_photos': 'Загрузите фотографии',
    'create_ad.title': 'Создание объявления',
    'edit_ad.title': 'Редактирование объявления',
    'error.auth_error': 'Ошибка авторизации',
    'error.sign_up_error': 'Не удалось создать пользователя',
    'error.something-went-wrong': 'Что-то пошло не так',
    'footer.made_with_love': 'Сделано с любовью на Северном Кипре.',
    'header.categoies_dropdown.all_categories': 'Все категории',
    'header.enter': 'Войти',
    'header.logout': 'Выход',
    'header.my_ads': 'Мои объявления',
    'header.search_placeholder': 'Я хочу найти...',
    'header.sign_up': 'Зарегистрироваться',
    'home.categories_header': 'Объявления по категориям',
    'home.head.title': 'Крупнейший маркетплейс Северного Кипра',
    'home.title.all_ads': 'Все объявления',
    'home.title.search_result': 'Результаты по запросу {searchString}',
    'label.post_ad': 'Разместить объявление',
    'no-data.message':
      'Отсутствуют результаты поика по вашему запросу. Попробуйте очистить результаты поиска в текущей категории или перейдите на <a>главную страницу</a> для нового поиска по всем категориям.',
    'no-data.title': 'Ничего не найдено',
    'no_price.message': 'Цена не указана',
    'not_found.back_button': 'Вернуться на главную',
    'not_found.header': 'Ошибка 404',
    'not_found.text': 'Страница не найдена',
    'sort.by_distance': 'По расстоянию',
    'sort.by_novelty': 'По новизне',
    'sort.by_price': 'По цене',
    'sort.default': 'По умолчанию',
    'validation.price_must_be_a_number': 'Цена должна быть числом',
    'validation.required': 'Обязательное поле',
  },
  en: {
    'ad_details.lable.go_to_src': 'Go to ad source',
    'ad_details.title.description': 'Description',
    'ad_details.title.properties': 'Ad properties',
    'auth.do_you_have_an_account': 'Do you already have an account?',
    'auth.label.email': 'Email',
    'auth.label.password': 'Password',
    'auth.label.password_confirm': 'Password confirmation',
    'auth.label.user_name': 'Username',
    'auth.login_form_title': 'Sign in to your account',
    'auth.sign_up_form_title': 'User registration',
    'breadcrumbs.home': 'Home',
    'category.filter.subcategories_title': 'Clarify the subcategory',
    'category.title.search_result': 'Results for {search} in category {title}',
    'create_ad.label.ad_description': 'Ad description',
    'create_ad.label.ad_tittle': 'Ad title',
    'create_ad.label.enter_ad_description': 'Enter ad description',
    'create_ad.label.enter_ad_tittle': 'Enter ad title',
    'create_ad.label.price': 'Price',
    'create_ad.label.select_category': 'Select a category',
    'create_ad.label.specify_price': 'Specify a price',
    'create_ad.label.specify_properties': 'Specify ad properties',
    'create_ad.label.upload_photos': 'Upload photos',
    'create_ad.title': 'Creating an ad',
    'edit_ad.title': 'Editing an ad',
    'error.auth_error': 'Authorisation error',
    'error.sign_up_error': 'Failed to create user',
    'error.something-went-wrong': 'Something went wrong',
    'footer.made_with_love': 'Made with love in Northern Cyprus.',
    'header.categoies_dropdown.all_categories': 'All categories',
    'header.enter': 'Sign In',
    'header.logout': 'Log out',
    'header.my_ads': 'My ads',
    'header.search_placeholder': 'I want to find...',
    'header.sign_up': 'Sign up',
    'home.categories_header': 'Ads by categories',
    'home.head.title': 'The largest marketplace in Northern Cyprus',
    'home.title.all_ads': 'All ads',
    'home.title.search_result': 'Results on request {searchString}',
    'label.post_ad': 'Post an ad',
    'no-data.message':
      'There are no search results for your query. Try clearing the search results in the current category, or go to the <a>main page</a> for a new search across all categories.',
    'no-data.title': 'Nothing found',
    'no_price.message': 'Price not specified',
    'not_found.back_button': 'Go back to the main page',
    'not_found.header': 'Error 404',
    'not_found.text': 'Page not found',
    'sort.by_distance': 'By distance',
    'sort.by_novelty': 'By novelty',
    'sort.by_price': 'By price',
    'sort.default': 'By default',
    'validation.price_must_be_a_number': 'Price must be a number',
    'validation.required': 'Required',
  },
  tr: {
    'ad_details.lable.go_to_src': 'Duyuruya git',
    'ad_details.title.description': 'Tanım',
    'ad_details.title.properties': 'Özellikler',
    'auth.do_you_have_an_account': 'Zaten bir hesabın var mı?',
    'auth.label.email': 'Email',
    'auth.label.password': 'Şifre',
    'auth.label.password_confirm': 'Şifre onayı',
    'auth.label.user_name': 'Kullanıcı adı',
    'auth.login_form_title': 'Hesabınıza giriş yapın',
    'auth.sign_up_form_title': 'Kullanıcı kaydı',
    'breadcrumbs.home': 'Ana Sayfa',
    'category.filter.subcategories_title': 'Alt kategoriyi netleştirin',
    'category.title.search_result':
      '{title} kategorisinde {arama} için sonuçlar',
    'create_ad.label.ad_description': 'Reklam açıklaması',
    'create_ad.label.ad_tittle': 'Reklam başlığı',
    'create_ad.label.enter_ad_description': 'Reklam açıklamasını girin',
    'create_ad.label.enter_ad_tittle': 'Reklam başlığı girin',
    'create_ad.label.price': 'Fiyat',
    'create_ad.label.select_category': 'Bir kategori seç',
    'create_ad.label.specify_price': 'Bir fiyat belirtin',
    'create_ad.label.specify_properties': 'Özellikleri belirtin',
    'create_ad.label.upload_photos': 'Fotoğrafları yükle',
    'create_ad.title': 'Reklam oluşturma',
    'edit_ad.title': 'Bir reklamı düzenleme',
    'error.auth_error': 'Yetkilendirme hatası',
    'error.sign_up_error': 'Kullanıcı oluşturulamadı',
    'error.something-went-wrong': 'Bir şeyler yanlış gitti',
    'footer.made_with_love': "Kuzey Kıbrıs'ta sevgi ile yapılmıştır.",
    'header.categoies_dropdown.all_categories': 'Tüm Kategoriler',
    'header.enter': 'Giriş Yap',
    'header.logout': 'Çıkış yap',
    'header.my_ads': 'Reklamlarım',
    'header.search_placeholder': 'Bulmak istiyorum ...',
    'header.sign_up': 'Kayıt ol',
    'home.categories_header': 'Kategorilere göre reklamlar',
    'home.head.title': "Kuzey Kıbrıs'ın en büyük pazar yeri",
    'home.title.all_ads': 'Tüm duyurular',
    'home.title.search_result': 'Talep üzerine sonuçlar {searchString}',
    'label.post_ad': 'ilan ver',
    'no-data.message':
      'Sorgunuz için arama sonucu yok. Mevcut kategorideki arama sonuçlarını temizlemeyi deneyin veya tüm kategorilerde yeni bir arama için <a>ana sayfaya</a> gidin.',
    'no-data.title': 'Hiçbirşey Bulunamadı',
    'no_price.message': 'Fiyat belirtilmedi',
    'not_found.back_button': 'Ana sayfaya geri dön',
    'not_found.header': 'Hata 404',
    'not_found.text': 'Sayfa bulunamadı',
    'sort.by_distance': 'Uzaklıkta',
    'sort.by_novelty': 'Yenilikte',
    'sort.by_price': 'Fiyatla',
    'sort.default': 'Varsayılan',
    'validation.price_must_be_a_number': 'Fiyat bir sayı olmalıdır',
    'validation.required': 'Gerekli alan',
  },
};

export default translations;

export const widgetTypes = {
  textField: 'text_feild',
  textSelect: 'text_select',
  digitalField: 'digital_field',
  price: 'price',
  bool: 'bool',
  dateField: 'date_field',
  datePeriod: 'date_period',
};

type Keys = keyof typeof widgetTypes;
export type WidgetType = typeof widgetTypes[Keys];

import { FC } from 'react';
import Dropdown from '../common/Dropdown';
import { ID, MenuOption } from '../common/types';
import styles from './LangDropdown.module.scss';

interface LangDropdownProps {
  langId: ID;
  options: MenuOption[];
  onChange: (langId: ID) => void;
}

const LangDropdown: FC<LangDropdownProps> = ({ options, langId, onChange }) => {
  const hangleChange = (value: string) => {
    onChange(Number(value));
  };

  return (
    <Dropdown
      className={styles.root}
      options={options}
      value={langId.toString()}
      onChange={hangleChange}
    />
  );
};

export default LangDropdown;

import { FC } from 'react';
import CardsPlaceholder from './CardsPlaceholder';

interface MultiCardsPlaceholderProps {
  length?: number;
  columns: 2 | 3;
}

const MultiCardsPlaceholder: FC<MultiCardsPlaceholderProps> = ({
  length = 6,
  columns,
}) => (
  <>
    {[...Array(length)].map((_, index) => (
      <div key={index} className={`col-${12 / columns}`}>
        <CardsPlaceholder />
      </div>
    ))}
  </>
);

export default MultiCardsPlaceholder;

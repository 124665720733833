import { FC, useEffect } from 'react';
import CategoriesTreeDropdown from './CategoriesTreeDropdown';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import useSearch from '../hooks/useSearch';

interface SearchFormValues {
  searchTerm: string;
}

const Search: FC = () => {
  const intl = useIntl();
  const [serchString, setSearchString] = useSearch();
  const formik = useFormik<SearchFormValues>({
    initialValues: {
      searchTerm: serchString,
    },
    onSubmit: ({ searchTerm }) => {
      setSearchString(searchTerm);
    },
  });

  useEffect(() => {
    if (serchString !== formik.values.searchTerm) {
      formik.setFieldValue('searchTerm', serchString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serchString]);

  return (
    <form onSubmit={formik.handleSubmit} className="header-search">
      <CategoriesTreeDropdown />
      <input
        type="text"
        aria-label="Search"
        className="search-bar"
        placeholder={intl.formatMessage({
          id: 'header.search_placeholder',
          defaultMessage: 'Я хочу найти...',
        })}
        name="searchTerm"
        value={formik.values.searchTerm}
        onChange={formik.handleChange}
      />
      <div
        className="search-button"
        role="button"
        onClick={() => formik.handleSubmit()}
      />
    </form>
  );
};

export default Search;

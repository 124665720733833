import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectIsAuth, selectUserSettingsStatus } from '../user/userSlice';
import { LoadingStatus } from './types';

const PrivateRoutesContainer = () => {
  const isAuth = useAppSelector<boolean>(selectIsAuth);
  const status = useAppSelector<LoadingStatus>(selectUserSettingsStatus);

  if (status === 'idle' || status === 'loading') return null; // waiting for user settings

  return isAuth ? <Outlet /> : <Navigate to="/#auth" />;
};

export default PrivateRoutesContainer;

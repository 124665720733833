import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Dropdown from '../../common/Dropdown';
import { currencyIdStorageKey } from '../constants';
import {
  selectCurrencyId,
  selectCurrencyOptions,
  setCurrencyId,
} from '../currencySlice';
import styles from './CurrencyDropdown.module.scss';

const CurrencyDropdownContainer: FC = () => {
  const dispatch = useAppDispatch();
  const currencyId = useAppSelector(selectCurrencyId);
  const options = useAppSelector(selectCurrencyOptions);

  const handleChange = (value: string) => {
    dispatch(setCurrencyId(Number(value)));
    localStorage.setItem(currencyIdStorageKey, value);
    window.location.reload();
  };

  return (
    <Dropdown
      className={styles.root}
      options={options}
      value={currencyId.toString()}
      onChange={handleChange}
    />
  );
};

export default CurrencyDropdownContainer;

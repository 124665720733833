import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectRootCategories } from '../../categories/categoriesSlice';
import { getCategoryPath } from '../../categories/components/Categories/utils';
import Dropdown from '../../common/Dropdown';
import { MenuOption } from '../../common/types';
import styles from '../styles.module.scss';

const CategoriesTreeDropdown: FC = () => {
  const intl = useIntl();
  const categories = useAppSelector(selectRootCategories);
  const navigate = useNavigate();
  const { categorySlug } = useParams();

  const options: MenuOption[] = [
    {
      label: intl.formatMessage({
        id: 'header.categoies_dropdown.all_categories',
        defaultMessage: 'Все категории',
      }),
      value: '',
    },
    ...categories.map(({ title, slug }) => ({
      label: title,
      value: slug,
    })),
  ];

  const handleChange = (value: string) => {
    const path = getCategoryPath({ slug: value });
    navigate(path);
  };

  return (
    <Dropdown
      className={styles.dropdown}
      labelClassName={styles.dropdownLable}
      aria-label="Select category"
      value={categorySlug || ''}
      onChange={handleChange}
      options={options}
    />
  );
};

export default CategoriesTreeDropdown;

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import FormattedPrice from '../../features/currency/components/FormattedPrice';
import CategoryBreadcrumb from '../../features/categories/components/CategoryBreadcrumb';
import { Ad } from '../../features/ads/types';
import Slider from './Slider';
import Properties from './Properties';
import { ID } from '../../features/common/types';
import styles from './AdDetails.module.scss';
import AuthorContacts from './AuthorContacts';

const getImgList = ({ img, imgs }: Ad): number[] => (imgs ? imgs : [img]);
const MAX_DESCRIPTION_LENGTH = 150;

const cutString = (str: string, length: number) =>
  str.substring(0, MAX_DESCRIPTION_LENGTH - 1) + '...';

interface AdDetailsProps {
  categoryId: ID;
  data: Ad;
  currencyCode?: string;
}

const AdDetails: FC<AdDetailsProps> = ({ categoryId, data, currencyCode }) => {
  const {
    title,
    body,
    url,
    properties,
    author_name,
    mail: email,
    phone,
    price,
  } = data;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={cutString(body, MAX_DESCRIPTION_LENGTH)}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <CategoryBreadcrumb categoryId={categoryId} />
            <div className="padding-5"></div>
            <h1>{title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <Slider images={getImgList(data)} />
            <div className="padding-5"></div>
            <h2>
              <FormattedMessage
                id="ad_details.title.description"
                defaultMessage="Описание"
              />
            </h2>
            <p>{body}</p>
            <div className="padding-5"></div>
            {properties && <Properties data={properties} />}
          </div>
          <div className="col-4">
            <div className="padding-20 mobile-only"></div>
            <div className={styles.priceWrap}>
              {currencyCode && (
                <FormattedPrice
                  value={price || 0}
                  currencyCode={currencyCode}
                  className="price"
                  noPriceClassName="center"
                />
              )}
              {url ? (
                <a className={styles.buttonGo} target="blank" href={`//${url}`}>
                  <div className={styles.buttonGoText}>
                    <FormattedMessage
                      id="ad_details.lable.go_to_src"
                      defaultMessage="Перейти к источнику"
                    />
                  </div>
                </a>
              ) : (
                <AuthorContacts
                  name={author_name}
                  email={email}
                  phone={phone}
                />
              )}
            </div>
            <div className={styles.socials}>
              <img src="/img/logo-facebook.svg" alt="facebook" />
              <img src="/img/logo-whatsapp.svg" alt="whatsapp" />
              <img src="/img/logo-twitter.svg" alt="twitter" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8"></div>
        </div>
      </div>
    </>
  );
};

export default AdDetails;

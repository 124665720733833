import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ID } from '../common/types';
import { localeIdStorageKey } from './constants';
import LangDropdown from './LangDropdown';
import {
  getLanguages,
  selectLangId,
  selectLanguageOptions,
  setLangId,
} from './langSlice';

const LangDropdownContainer: FC = () => {
  const dispatch = useAppDispatch();
  const langId = useAppSelector(selectLangId);
  const options = useAppSelector(selectLanguageOptions);

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  const handleChange = (id: ID) => {
    dispatch(setLangId(id));
    localStorage.setItem(localeIdStorageKey, id.toString());
    window.location.reload();
  };

  return (
    <LangDropdown options={options} langId={langId} onChange={handleChange} />
  );
};

export default LangDropdownContainer;

import { FC } from 'react';
import Search from '../features/search/components/Search';
import Auth from '../features/user/components/Auth';
import PreHeaderContainer from './PreHeaderContainer';
import styles from './styles.module.scss';

const AppHeader: FC = () => {
  return (
    <div className={styles.headerWrap}>
      <PreHeaderContainer />
      <header>
        <div className="container">
          <div className="header-wrap">
            <div className="header-logo">
              <a href="/">
                <img src="/img/logo.svg" alt="1way.market" />
              </a>
            </div>
            <Search />
            <Auth />
          </div>
        </div>
      </header>
    </div>
  );
};

export default AppHeader;

import { FC, useRef } from 'react';
import cn from 'classnames';
import { MenuOption, MenuPosition } from './types';
import styles from './styles.module.scss';
import useOnClickOutside from './useOnClickOutside';

interface MenuProps {
  open: boolean;
  options: MenuOption[];
  onSelect: (value: string) => void;
  onClose: () => void;
  position?: MenuPosition;
  selectedValue?: string;
  className?: string;
}

const Menu: FC<MenuProps> = ({
  open,
  options,
  onSelect,
  onClose,
  position = 'left',
  selectedValue,
  className,
}) => {
  const ref = useRef<HTMLUListElement>(null);

  useOnClickOutside<HTMLUListElement>(ref, onClose);

  if (!open) {
    return null;
  }

  const style = {
    left: position === 'left' ? 0 : 'inherited',
    right: position === 'right' ? 0 : 'inherited',
  };

  return (
    <ul className={cn(styles.menu, className)} ref={ref} style={style}>
      {options.map(({ label, value, icon }) => (
        <li
          key={value}
          role="option"
          aria-selected="false"
          onClick={() => onSelect(value)}
          className={cn({ active: value === selectedValue })}
          title={label}
        >
          <span className={styles.icon}>{icon}</span>
          {label}
        </li>
      ))}
    </ul>
  );
};
export default Menu;

import { FC, ReactNode } from 'react';
import styles from './Alert.module.scss';

interface AlertProps {
  children: ReactNode;
}

const Alert: FC<AlertProps> = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

export default Alert;

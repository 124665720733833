import { configureStore } from '@reduxjs/toolkit';
import categoriesReducer from '../features/categories/categoriesSlice';
import adsReducer from '../features/ads/adSlice';
import currencyReducer from '../features/currency/currencySlice';
import userReducer, {
  refreshTokenMiddleware,
} from '../features/user/userSlice';
import filterReducer from '../features/filters/filtersSlice';
import langReducer from '../features/lang/langSlice';
import categoryDetailsReducer from '../features/categories/categoryDetailsSlice';
import myAdsReducer from '../features/myAds/myAdsSlice';

export const rootReducer = {
  categories: categoriesReducer,
  categoryDetails: categoryDetailsReducer,
  ads: adsReducer,
  filters: filterReducer,
  currency: currencyReducer,
  user: userReducer,
  lang: langReducer,
  myAds: myAdsReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(refreshTokenMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { ChangeEventHandler, FC, useState } from 'react';
import { FcAddImage } from 'react-icons/fc';
import api from '../../../utils/api';
import ImageManagerItem from './ImageManagerItem';
import styles from './ImageManager.module.scss';
import { ID, LoadingStatus } from '../../../features/common/types';
import Spinner from '../../../features/common/Spinner';

interface ImageManagerProps {
  images: number[];
  onChange: (images: number[]) => void;
}

const MAX_SIZE = 5 * 10 ** 6;

const uploadImage = async (file: File) => {
  if (file.size > MAX_SIZE) {
    return;
  }
  const [title, format] = file.name.split('.');
  const { data: guid } = await api.post('/img', {
    title,
    format,
  });
  const { data: imageId } = await api.put(`/img/${guid}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return imageId;
};

const ImageManager: FC<ImageManagerProps> = ({ images, onChange }) => {
  const [status, setStatus] = useState<LoadingStatus>('idle');

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> = (event) => {
    const selectedImage = event.target.files?.[0];
    if (!selectedImage) {
      return;
    }
    setStatus('loading');
    uploadImage(selectedImage)
      .then((imageId) => {
        onChange([...images, imageId]);
      })
      .catch(() => {
        setStatus('failed');
      })
      .then(() => {
        event.target.value = '';
        setStatus('success');
      });
  };

  const handleDelete = (id: ID) => {
    onChange(images.filter((img) => img !== id));
  };

  return (
    <div className={styles.root}>
      {images.map((image) => (
        <ImageManagerItem key={image} imageId={image} onDelete={handleDelete} />
      ))}
      {status === 'loading' && (
        <div>
          <Spinner />
        </div>
      )}

      <label htmlFor="file-upload" className={styles.fileUpload}>
        <FcAddImage />
      </label>
      <input id="file-upload" type="file" onChange={handleFileSelect} />
    </div>
  );
};

export default ImageManager;

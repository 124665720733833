import {
  createSlice,
  createAsyncThunk,
  Middleware,
  Selector,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import api from '../../utils/api';
import { hash } from '../../utils/auth';
import {
  getAccessToken,
  removeRefreshToken,
  setRefreshToken,
} from '../../utils/tokens';
import { LoadingStatus } from '../common/types';

export interface UserState {
  userRegistrationStatus: LoadingStatus;
  userAuthStatus: LoadingStatus;
  settings: {
    status: LoadingStatus;
    data: UserSettings | null;
  };
}

const initialState: UserState = {
  userRegistrationStatus: 'idle',
  userAuthStatus: 'idle',
  settings: {
    status: 'idle',
    data: null,
  },
};

interface RegisterUserArgs {
  data: {
    name: string;
    phone?: string;
    mail: string;
    passwordHex: string;
  };
  onSuccess: (refreshToken: string) => void;
}

export const registerUser = createAsyncThunk<string, RegisterUserArgs>(
  'user/registerUser',
  async ({ data, onSuccess }) => {
    const response = await api.post('/user', data);
    onSuccess(response.data);
    return response.data;
  }
);

interface UserSettings {
  lang: number;
  currency: number;
  name: string;
  phone: string;
  mail: string;
}

export const getSettings = createAsyncThunk<UserSettings>(
  'user/getSettings',
  async () => {
    const key = await getAccessToken();
    const response = await api.get('/settings', { headers: { key } });
    return response.data;
  }
);

interface LoginArgs {
  name: string;
  password: string;
}

export const login = createAsyncThunk<void, LoginArgs>(
  'user/login',
  async ({ name, password }, thunkApi) => {
    const passwordHex = hash(password);
    const response = await api.post('/login', { name, passwordHex });
    setRefreshToken(response.data);
    thunkApi.dispatch(getSettings());
  }
);

export const refreshTokenMiddleware: Middleware = () => (next) => (action) => {
  if (userSlice.actions.logout.match(action)) {
    removeRefreshToken();
  }
  return next(action);
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.userRegistrationStatus = 'idle';
    },
    logout: (state) => {
      state.settings.status = 'idle';
      state.settings.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.userAuthStatus = 'loading';
    });

    builder.addCase(login.fulfilled, (state) => {
      state.userAuthStatus = 'success';
    });

    builder.addCase(login.rejected, (state) => {
      state.userAuthStatus = 'failed';
    });

    builder.addCase(registerUser.pending, (state) => {
      state.userRegistrationStatus = 'loading';
    });

    builder.addCase(registerUser.fulfilled, (state) => {
      state.userRegistrationStatus = 'success';
    });

    builder.addCase(registerUser.rejected, (state) => {
      state.userRegistrationStatus = 'failed';
    });

    builder.addCase(getSettings.pending, (state) => {
      state.settings.status = 'loading';
    });

    builder.addCase(getSettings.fulfilled, (state, { payload }) => {
      state.settings.data = payload;
      state.settings.status = 'success';
    });

    builder.addCase(getSettings.rejected, (state) => {
      state.settings.status = 'failed';
    });
  },
});

export const { reset, logout } = userSlice.actions;

export const selectIsUserRegistring: Selector<RootState, boolean> = (state) =>
  state.user.userRegistrationStatus === 'loading';

export const selectIsUserRegistationFailed: Selector<RootState, boolean> = (
  state
) => state.user.userRegistrationStatus === 'failed';

export const selectIsUserAuthFailed: Selector<RootState, boolean> = (state) =>
  state.user.userAuthStatus === 'failed';

export const selectIsAuth: Selector<RootState, boolean> = (state) =>
  Boolean(state.user.settings.data);

export const selectUserName: Selector<RootState, string> = (state) =>
  state.user.settings.data?.name || '';

export const selectUserSettingsStatus: Selector<RootState, LoadingStatus> = (
  state
) => state.user.settings.status;

export const selectIsUserSettingsLoading: Selector<RootState, boolean> = (
  state
) => state.user.settings.status === 'loading';

export default userSlice.reducer;

import { FC, useState } from 'react';
import { ImExit } from 'react-icons/im';
import { FormattedMessage } from 'react-intl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import styles from './styles.module.scss';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

interface UserMenuProps {
  onLogout: () => void;
}

const UserMenu: FC<UserMenuProps> = ({ onLogout }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMyAds = () => {
    navigate('/my');
    handleClose();
  };

  const handleLogout = () => {
    onLogout();
  };

  return (
    <>
      <button className="login_button" onClick={handleClick}>
        <IoPersonCircleOutline className={styles.personIcon} />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenMyAds}>
          <FormattedMessage
            id="header.my_ads"
            defaultMessage="Мои объявления"
          />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ImExit className={styles.exitIcon} />
          <FormattedMessage id="header.logout" defaultMessage="Выход" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;

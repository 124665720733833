import { FC } from 'react';
import CategorySelectorItemContainer from './CategorySelectorItemContainer';
import styles from './CategorySelector.module.scss';
import { ID } from '../../../features/common/types';
import FieldLabel from '../../../features/common/FieldLabel';
import { FormattedMessage } from 'react-intl';

interface CategorySelectorProps {
  onChange: (value: ID | null) => void;
}

const CategorySelector: FC<CategorySelectorProps> = ({ onChange }) => {
  return (
    <>
      <FieldLabel isRequired>
        <FormattedMessage
          id="create_ad.label.select_category"
          defaultMessage="Выберите категорию"
        />
      </FieldLabel>
      <div className={styles.body}>
        <CategorySelectorItemContainer categoryId={0} onChange={onChange} />
      </div>
    </>
  );
};

export default CategorySelector;

import { ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.scss';

interface ButtonPops extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: FC<ButtonPops> = ({ ...props }) => {
  return <button className={styles.root} {...props} />;
};

export default Button;

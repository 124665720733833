import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';

const NotFound: FC = () => (
  <section className={styles.root}>
    <h1>
      <FormattedMessage id="not_found.header" defaultMessage="Ошибка 404" />
    </h1>
    <p>
      <FormattedMessage
        id="not_found.text"
        defaultMessage="Страница не найдена"
      />
    </p>
    <div className="padding_10" />
    <Link className={styles.button} to="/">
      <FormattedMessage
        id="not_found.back_button"
        defaultMessage="Вернуться на главную"
      />
    </Link>
  </section>
);

export default NotFound;

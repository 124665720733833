import { FilterOption, Filters } from './types';

export const isOptionFound = (
  sourse: FilterOption[],
  value: FilterOption
): boolean =>
  sourse.some((item) => {
    if (value.ID === 0) return value.title === item.title;
    return item.ID === value.ID;
  });

/** Soft update checkboxes filters with disabling missed items */
export const updateCheckboxexFilterOptions = (
  values: FilterOption[],
  payload: FilterOption[]
): FilterOption[] =>
  values.map((value) =>
    isOptionFound(payload, value)
      ? { ...value, disabled: false }
      : { ...value, disabled: true }
  );

/** Soft update filters with disabling missed items */
export const updateFilters = (state: Filters, payload: Filters): Filters => {
  return state.map((filter) => {
    const resievedFilter = payload.find(
      (item) => item.property === filter.property
    );
    return {
      ...filter,
      values: updateCheckboxexFilterOptions(
        filter.values,
        resievedFilter?.values || []
      ),
    };
  });
};

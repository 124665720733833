import { FC } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { selectRootCategories } from '../../categoriesSlice';
import { useAppSelector } from '../../../../app/hooks';
import CategoryCard from './CategoryCard';
import styles from './Categories.module.scss';

const Categories: FC = () => {
  const rootCategories = useAppSelector(selectRootCategories);

  if (isEmpty(rootCategories)) {
    return null;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="center">
            <FormattedMessage
              id="home.categories_header"
              defaultMessage="Объявления по категориям"
            />
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="padding_5"></div>
        <div className="col-12">
          <div className={styles.block}>
            {rootCategories.map((category) => (
              <CategoryCard key={category.ID} category={category} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;

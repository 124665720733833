import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorGetter } from '../types';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { login, selectIsUserAuthFailed } from '../userSlice';
import styles from './styles.module.scss';
import Modal from '../../common/Modal';
import TextField from '../../common/TextField';
import Button from '../../common/Button';
import Alert from '../../common/Alert';
import { FormattedMessage, useIntl } from 'react-intl';

interface SignInFormValues {
  name: string;
  password: string;
}
interface SignInFormProps {
  onSubmit: (values: SignInFormValues) => void;
  onHide: () => void;
  show: boolean;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  password: Yup.string().required('Password is required'),
});

const AuthFormModal: FC<SignInFormProps> = ({ onSubmit, onHide, show }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isFailed = useAppSelector<boolean>(selectIsUserAuthFailed);
  const formik = useFormik<SignInFormValues>({
    initialValues: {
      name: '',
      password: '',
    },
    validationSchema,
    onSubmit: () => {
      dispatch(login(formik.values));
    },
  });

  const getError: ErrorGetter<SignInFormValues> = (key) => {
    if (formik.touched[key]) {
      return formik.errors[key];
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <div className="login_title">
        <FormattedMessage
          id="auth.login_form_title"
          defaultMessage="Войдите в свой аккаунт"
        />
      </div>
      <div className="login_form">
        {isFailed && (
          <Alert>
            <FormattedMessage
              id="error.auth_error"
              defaultMessage="Ошибка авторизации"
            />
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="name"
            value={formik.values.name}
            type="text"
            className="login_input_text"
            placeholder={intl.formatMessage({
              id: 'auth.label.email',
              defaultMessage: 'Электронная почта',
            })}
            onChange={formik.handleChange}
            error={getError('name')}
          />

          <TextField
            type="password"
            name="password"
            value={formik.values.password}
            className="login_input_text"
            placeholder={intl.formatMessage({
              id: 'auth.label.password',
              defaultMessage: 'Пароль',
            })}
            onChange={formik.handleChange}
            error={getError('password')}
          />

          <Button type="submit">
            <FormattedMessage id="header.enter" defaultMessage="Войти" />
          </Button>
          <div className={styles.registerLinkWrap}>
            <Link to="#registration">
              <FormattedMessage
                id="header.sign_up"
                defaultMessage="Зарегистрироваться"
              />
            </Link>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AuthFormModal;

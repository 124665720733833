import { Category } from '../../../categories/types';
import { getCategoryPath } from '../Categories/utils';
import { BreadcrumbItem } from './types';

const convertToBreadcrumbItem = ({
  title,
  ID,
  slug,
}: Category): BreadcrumbItem => ({
  ID: ID,
  text: title,
  path: getCategoryPath({ slug }),
});

export const getBreadcrumbItems = (
  categories: Category[],
  currentCategoryId: number
): BreadcrumbItem[] => {
  const currentCategory: Category | undefined = categories.find(
    (category) => category.ID === currentCategoryId
  );
  if (!currentCategory) {
    return [];
  }
  if (currentCategory.parentID) {
    return [
      ...getBreadcrumbItems(categories, currentCategory.parentID),
      convertToBreadcrumbItem(currentCategory),
    ];
  }

  return [convertToBreadcrumbItem(currentCategory)];
};

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const NoDataMessage: FC = () => (
  <div className="center">
    <h4>
      <FormattedMessage id="no-data.title" defaultMessage="Ничего не найдено" />
    </h4>
    <p>
      <FormattedMessage
        id="no-data.message"
        defaultMessage="Отсутствуют результаты поика по вашему запросу. Попробуйте очистить
        результаты поиска в текущей категории или перейдите на 
        <a>главную страницу</a> для нового поиска по всем категориям."
        values={{
          a: (chunks: string) => <a href="/">{chunks}</a>,
        }}
      />
    </p>
  </div>
);

export default NoDataMessage;

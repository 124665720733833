import { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CurrencyDropdownContainer from '../features/currency/components/CurrencyDropdownContainer';
import LangDropdownContainer from '../features/lang/LangDropdownContainer';
import styles from './styles.module.scss';

interface PreHeaderProps {
  isAuth: boolean;
}

const PreHeader: FC<PreHeaderProps> = ({ isAuth }) => {
  return (
    <div className={styles.preHeader}>
      <div className={cn('container', styles.preHeaderContainer)}>
        <CurrencyDropdownContainer key="currency" />
        <LangDropdownContainer key="lang" />
        {isAuth && (
          <Link key="add-item" to="/add" className={styles.addButton}>
            <FormattedMessage
              id="label.post_ad"
              defaultMessage="Разместить объявление"
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default PreHeader;

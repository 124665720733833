import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const Footer: FC = () => (
  <footer>
    <div className="footer-text">
      1way.market &copy; {new Date().getFullYear()}.{' '}
      <FormattedMessage
        id="footer.made_with_love"
        defaultMessage="Сделано с любовью на Северном Кипре."
      />
    </div>
  </footer>
);

export default Footer;

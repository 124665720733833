import { FC, useRef, ReactNode } from 'react';
import cn from 'classnames';
import styles from './Modal.module.scss';
import useOnClickOutside from './useOnClickOutside';

interface ModalProps {
  show: boolean;
  children: ReactNode;
  onHide: () => void;
}

const Modal: FC<ModalProps> = ({ show, children, onHide }) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>(ref, onHide);
  return (
    <div ref={ref} className={cn(styles.modal, { [styles.open]: show })}>
      {children}
    </div>
  );
};

export default Modal;

import { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

interface FormattedPriceProps {
  value: number;
  currencyCode: string;
  className?: string;
  noPriceClassName?: string;
}

const FormattedPrice: FC<FormattedPriceProps> = ({
  value,
  currencyCode,
  className,
  noPriceClassName,
}) => {
  if (!value) {
    return (
      <div className={noPriceClassName}>
        <FormattedMessage
          id="no_price.message"
          defaultMessage="Цена не указана"
        />
      </div>
    );
  }
  return (
    <div className={className}>
      <FormattedNumber
        value={value}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency={currencyCode}
        currencyDisplay="narrowSymbol"
        minimumFractionDigits={0}
      />
    </div>
  );
};

export default FormattedPrice;

import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Category } from '../../../categories/types';
import { useAppSelector } from '../../../../app/hooks';
import { selectCategoriesData } from '../../../categories/categoriesSlice';
import { getBreadcrumbItems } from './utils';
import { FormattedMessage } from 'react-intl';

interface CategoryBreadcrumbProps {
  categoryId: number;
  isCurrentCategoryActive?: boolean;
}

const CategoryBreadcrumb: FC<CategoryBreadcrumbProps> = ({
  categoryId,
  isCurrentCategoryActive = true,
}) => {
  const categories = useAppSelector<Category[]>(selectCategoriesData);
  const items = getBreadcrumbItems(categories, categoryId);

  const isCurrentCategory = (index: number): boolean =>
    index === items.length - 1;

  return (
    <ol className="breadcrumb">
      <li>
        <Link to="/">
          <FormattedMessage id="breadcrumbs.home" defaultMessage="Главная" />
        </Link>
      </li>
      {items.map((item, index) => {
        const isActive = isCurrentCategoryActive && isCurrentCategory(index);
        return (
          <li
            key={item.ID}
            className={cn({
              active: isActive,
            })}
          >
            {isActive ? item.text : <Link to={item.path}>{item.text}</Link>}
          </li>
        );
      })}
    </ol>
  );
};

export default CategoryBreadcrumb;

import { FC } from 'react';
import { widgetTypes } from './constants';
import { Filter, FilterItem, FilterOption } from '../../filters/types';
import CheckboxList from './CheckboxList';
import { selectFilterPropertyValue, setFilter } from '../filtersSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ID } from '../../common/types';
import styles from './Widget.module.scss';

interface WidgetProps {
  filter: Filter;
  categoryId: ID;
}

const Widget: FC<WidgetProps> = ({ filter, categoryId }) => {
  const dispatch = useAppDispatch();
  const filterValue = useAppSelector<FilterOption[]>((state) =>
    selectFilterPropertyValue(state, categoryId, filter.property)
  );

  const handleCheckboxListChange = (filterItem: FilterItem) => {
    dispatch(setFilter({ categoryId, filterItem }));
  };

  switch (filter.widget) {
    case widgetTypes.textSelect:
      return (
        <>
          <div className={styles.title}>{filter.title}</div>
          <CheckboxList
            options={filter.values}
            value={filterValue}
            onChange={(values) =>
              handleCheckboxListChange({ ID: filter.property, values })
            }
          />
        </>
      );
    default:
      console.error('Widget not found');
      return null;
  }
};

export default Widget;

import axios from 'axios';
import { currencyIdStorageKey, RUB_CODE } from '../features/currency/constants';
import { localeIdStorageKey, RU_LANG_ID } from '../features/lang/constants';

const baseURL = 'https://back1.1way.market/board/hs/v1';

const api = axios.create({
  baseURL,
  responseType: 'json',
});

api.interceptors.request.use((config) => {
  const lang = localStorage.getItem(localeIdStorageKey) || RU_LANG_ID;
  const currency = localStorage.getItem(currencyIdStorageKey) || RUB_CODE;

  if (!config.headers) {
    config.headers = {};
  }

  config.headers.lang = lang;
  config.headers.currency = currency;

  return config;
});

export const getImgUrl = (id: number): string => `${baseURL}/img/${id}`;

export const getSmallImgUrl = (id: number): string =>
  `${baseURL}/img_low/${id}`;

export default api;

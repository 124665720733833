import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { Category } from '../../features/categories/types';
import { getCategoryPath } from '../../features/categories/components/Categories/utils';

interface SubcategoryListProps {
  list: Category[];
}

const SubcategoryList: FC<SubcategoryListProps> = ({ list }) => {
  if (isEmpty(list)) return null;

  return (
    <>
      <div className="filter-title">
        <FormattedMessage
          id="category.filter.subcategories_title"
          defaultMessage="Уточнить подкатегорию"
        />
      </div>
      <ul>
        {list.map((category) => (
          <li key={category.ID}>
            <Link to={getCategoryPath(category)}>{category.title}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SubcategoryList;

import { FC } from 'react';
import { useIntl } from 'react-intl';
import Dropdown from '../../../common/Dropdown';
import { MenuOption } from '../../../common/types';
import { AdSort } from '../../types';
import styles from './SortDropdown.module.scss';

interface SortDropdownProps {
  value: AdSort;
  onSelect: (value: AdSort) => void;
}

const convertToAdSort = (value: string | null): AdSort => {
  const num = Number(value);
  if (!(num in AdSort)) {
    throw Error('Invalid enum value');
  }
  return num;
};

const SortDropdown: FC<SortDropdownProps> = ({ value, onSelect }) => {
  const intl = useIntl();
  const handleSelect = (value: string | null) => {
    onSelect(convertToAdSort(value));
  };

  const labelsMap = {
    [AdSort.Default]: intl.formatMessage({
      id: 'sort.default',
      defaultMessage: 'По умолчанию',
    }),
    [AdSort.Novelty]: intl.formatMessage({
      id: 'sort.by_novelty',
      defaultMessage: 'По новизне',
    }),
    [AdSort.Cost]: intl.formatMessage({
      id: 'sort.by_price',
      defaultMessage: 'По цене',
    }),
  };

  const options: MenuOption[] = Object.entries(labelsMap).map(
    ([value, label]) => ({
      value,
      label,
    })
  );

  return (
    <Dropdown
      className={styles.root}
      options={options}
      value={value.toString()}
      onChange={handleSelect}
      menuPosition="right"
    />
  );
};

export default SortDropdown;

import { createSlice, createAsyncThunk, Selector } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import api from '../../utils/api';
import { ID, LoadingStatus, MenuOption } from '../common/types';
import { currencyIconsMap, currencyIdStorageKey, RUB_CODE } from './constants';
import { Currency } from './types';

export interface CurrencyState {
  currentId: ID;
  data: Currency[];
  status: LoadingStatus;
}

const initialState: CurrencyState = {
  currentId: Number(localStorage.getItem(currencyIdStorageKey) || RUB_CODE),
  data: [],
  status: 'idle',
};

export const getCurrency = createAsyncThunk(
  'currencies/getCurrency',
  async () => {
    const response = await api.get('/currency');
    return response.data;
  }
);

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrencyId: (state, { payload }) => {
      state.currentId = payload;
    },

    reset: (state) => {
      state.data = [];
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrency.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getCurrency.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.status = 'success';
    });

    builder.addCase(getCurrency.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { reset, setCurrencyId } = currencySlice.actions;

export const selectCurrencyOptions: Selector<RootState, MenuOption[]> = (
  state
) =>
  state.currency.data.map((currency) => ({
    value: currency.ID.toString(),
    label: currency.code,
    icon: currencyIconsMap.get(currency.code),
  }));

export const selectCurrencyId: Selector<RootState, ID> = (state) =>
  state.currency.currentId;

export const selectCurrency: Selector<RootState, Currency | null> = (state) => {
  return (
    state.currency.data.find((item) => item.ID === state.currency.currentId) ||
    null
  );
};

export default currencySlice.reducer;

import { FC } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';
import CategoryBreadcrumb from '../../features/categories/components/CategoryBreadcrumb';
import CategoryTitle from '../../features/categories/components/CategoryTitle';
import useSearch from '../../features/search/hooks/useSearch';
import { Filter as FilterType } from '../../features/filters/types';
import Filter from './Filter';
import { Category as CategoryType } from '../../features/categories/types';
import AdsContainer from '../../features/ads/components/AdsContainer';
import SortDropdown from '../../features/ads/components/SortDropdown';
import AdCardContainer from '../../features/ads/components/AdCardContainer';
import NoDataMessage from '../../features/ads/components/NoDataMessage';
import MultiCardsPlaceholder from '../../features/ads/components/MultiCardsPlaceholder';

interface CategoryProps {
  category: CategoryType;
  subcategories: CategoryType[];
  filters: FilterType[];
  isFiltersLoading: boolean;
}

const Category: FC<CategoryProps> = ({
  category,
  subcategories,
  filters,
  isFiltersLoading,
}) => {
  const [searchTerm] = useSearch();

  const hasFilters = !isEmpty(subcategories) || !isEmpty(filters);
  const hasSidebar = isFiltersLoading || hasFilters;

  return (
    <>
      <Helmet>
        <title>{category.title}</title>
      </Helmet>
      <AdsContainer categoryId={category.ID}>
        {({ data, sort, handleSort, status }) => (
          <article>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <CategoryBreadcrumb categoryId={category.ID} />
                </div>
                {hasSidebar && (
                  <div className="col-12 center">
                    <CategoryTitle title={category.title} search={searchTerm} />
                  </div>
                )}
              </div>

              <div className="row">
                {hasSidebar ? (
                  <>
                    <div className="col-4">
                      <Filter
                        subcategories={subcategories}
                        filters={filters}
                        isLoading={isFiltersLoading}
                      />
                    </div>

                    <div className="col-8">
                      <div className="col-12 right">
                        <SortDropdown value={sort} onSelect={handleSort} />
                        <div className="padding-5"></div>
                      </div>

                      {status === 'success' && data.length === 0 && (
                        <NoDataMessage />
                      )}

                      {data.map((item) => (
                        <div key={item.ID} className="col-6">
                          <AdCardContainer ad={item} />
                        </div>
                      ))}

                      {status === 'loading' && (
                        <MultiCardsPlaceholder columns={2} />
                      )}
                    </div>
                  </>
                ) : (
                  <article>
                    <div className="row">
                      <div className="col-6-sm">
                        <CategoryTitle
                          title={category.title}
                          search={searchTerm}
                        />
                      </div>
                      <div className="col-6-sm right">
                        {sort !== undefined && (
                          <SortDropdown value={sort} onSelect={handleSort} />
                        )}
                      </div>
                    </div>

                    <div className="padding-20"></div>
                    <div className="row">
                      {status === 'success' && data.length === 0 && (
                        <div key="no-data-message" className="coll-12">
                          <NoDataMessage />
                        </div>
                      )}
                      {data.map((item) => (
                        <div key={item.ID} className="col-4">
                          <AdCardContainer ad={item} />
                        </div>
                      ))}
                      {status === 'loading' && (
                        <MultiCardsPlaceholder columns={3} />
                      )}
                    </div>
                  </article>
                )}
              </div>
            </div>
          </article>
        )}
      </AdsContainer>
    </>
  );
};

export default Category;

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { AdProperty } from '../../features/ads/types';

interface PropertiesProps {
  data: AdProperty[];
}

const Properties: FC<PropertiesProps> = ({ data }) => (
  <>
    <h2>
      <FormattedMessage
        id="ad_details.title.properties"
        defaultMessage="Характеристики"
      />
    </h2>
    <table className="characteristics">
      <tbody>
        {data.map(({ title, value }) => (
          <tr key={title}>
            <td>{title}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

export default Properties;

import { IntlProvider } from 'react-intl';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import { getCategories } from '../features/categories/categoriesSlice';
import { getCurrency } from '../features/currency/currencySlice';
import { Home, AdDetailsContainer, CategoryContainer } from '../pages';
import { getSettings } from '../features/user/userSlice';
import CommonLayout from '../layout/CommonLayout';
import { useAppDispatch, useAppSelector } from './hooks';
import { selectLocale } from '../features/lang/langSlice';
import { Locale } from '../features/lang/types';
import translations from '../translations';
import { AddAdFormContainer, EditAdFormContainer } from '../pages/AdForm';
import PrivateRoutesContainer from '../features/common/PrivateRoutesContainer';
import { MyAdsContainer } from '../pages/MyAds';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectLocale);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getCategories());
    dispatch(getSettings());
  }, [dispatch]);

  const getMessages = (locale: Locale) => translations[locale];

  return (
    <IntlProvider locale={locale} messages={getMessages(locale)}>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <Routes key={locale}>
        <Route path="/" element={<CommonLayout />}>
          <Route index element={<Home />} />
          <Route element={<PrivateRoutesContainer />}>
            <Route path="add" element={<AddAdFormContainer />} />
            <Route path="my" element={<MyAdsContainer />} />
            <Route path="edit/:adId" element={<EditAdFormContainer />} />
          </Route>
          <Route path="c/:categorySlug" element={<CategoryContainer />} />
          <Route path=":adSlug" element={<AdDetailsContainer />} />
        </Route>
      </Routes>
    </IntlProvider>
  );
};

export default App;

import { FC } from 'react';
import { useIntl } from 'react-intl';

interface CategoryTitleProps {
  title: string;
  search?: string;
}

const CategoryTitle: FC<CategoryTitleProps> = ({ title, search }) => {
  const intl = useIntl();

  return (
    <h2>
      {search
        ? intl.formatMessage(
            {
              id: 'category.title.search_result',
              defaultMessage:
                'Результаты по запросу {search} в категории {title}',
            },
            { search, title }
          )
        : title}
    </h2>
  );
};

export default CategoryTitle;

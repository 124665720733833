import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from './AppHeader';
import Footer from './Footer';

const CommonLayout: FC = () => (
  <>
    <AppHeader />
    <Outlet />
    <div className="div-space"></div>
    <Footer />
  </>
);

export default CommonLayout;

import { FC, ReactNode } from 'react';
import styles from './FieldLabel.module.scss';

interface FieldLabelProps {
  children: ReactNode;
  isRequired?: boolean;
}

const FieldLabel: FC<FieldLabelProps> = ({ children, isRequired }) => {
  return (
    <>
      <p>
        {children} {isRequired && <span className={styles.required}>*</span>}
      </p>
      <div className="padding_5"></div>
    </>
  );
};

export default FieldLabel;

import { ID, LoadingStatus } from '../common/types';
import { RequestProperty } from '../filters/types';

export enum AdStatus {
  Draft,
  AwaitingVerification,
  FromParser,
  Actual,
  Completed,
  Rejected,
}

export enum AdSort {
  Default,
  Distance,
  Novelty,
  Cost,
}

interface BaseAd {
  title: string;
  img: number;
  price: number;
  currency: number;
}

export interface AdProperty {
  ID: number;
  title: string;
  value: string;
  widget: string;
  type: string;
  parents: number[];
}

export interface Ad extends BaseAd {
  ID: number;
  categories: number[];
  body: string;
  imgs?: number[];
  status: number;
  properties: AdProperty[];
  date: string;
  best: number;
  geocode: unknown;
  distance: number;
  price_orig: number;
  url?: string;
  author_name?: string;
  mail?: string;
  phone?: string;
}

export interface AdListItem extends BaseAd {
  ID: number;
  best: boolean;
  distance?: number;
  price_orig: number;
  img_url?: string;
  slug: string;
}

interface BaseWriteArgs extends BaseAd {
  categoryID?: number;
  categories: number[];
  body: string;
  imgs: number[];
  properties: number[];
  geocode?: unknown;
}

export interface PropertyItem {
  ID: ID;
  value: ID | string;
}

export interface AddAdArgs {
  imgs: ID[];
  img: ID;
  price: number;
  currency: ID;
  body: string;
  title: string;
  lang: ID;
  categoryID: ID;
  properties: PropertyItem[];
}

export interface EditAdArgs extends BaseWriteArgs {
  ID: ID;
}

export interface FetchAdListArgs {
  max: number;
  pre_last: ID;
  categories: number[];
  status?: AdStatus;
  likes?: string;
  best?: boolean; //  only for authorized users
  sort?: AdSort;
  properties?: RequestProperty[];
}

export interface AdsData {
  data: AdListItem[];
  status: LoadingStatus;
  sort: AdSort;
  handleSort: (value: AdSort) => void;
}

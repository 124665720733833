import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {
  AdState,
  getAds,
  resetAds,
  selectAdsState,
  selectHasMore,
  selectLastId,
} from '../adSlice';
import { getFilters, setSort } from '../../filters/filtersSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useSearch from '../../search/hooks/useSearch';
import useRequestArgs from './useRequestArgs';
import { AdsData, AdSort } from '../types';
import { ID } from '../../common/types';

const offset = 600;

const useAdsData = (categoryID: ID): AdsData => {
  const dispatch = useAppDispatch();
  const ads = useAppSelector<AdState>(selectAdsState);
  const lastLoadedId = useAppSelector<number>(selectLastId);
  const hasMore = useAppSelector<boolean>(selectHasMore);
  const [likes] = useSearch();
  const requestArgs = useRequestArgs(categoryID);

  useEffect(() => {
    dispatch(resetAds());
    dispatch(getAds(requestArgs));
    if (!isEmpty(requestArgs.categories)) {
      dispatch(getFilters(omit(requestArgs, ['max', 'pre_last'])));
    }
  }, [dispatch, requestArgs]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [categoryID]);

  const handleWindowScroll: EventListener = debounce((e) => {
    if (ads.status === 'loading') {
      return;
    }

    const isBottom =
      document.documentElement.clientHeight +
        document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - offset;

    if (isBottom && hasMore && requestArgs) {
      dispatch(
        getAds({
          ...requestArgs,
          pre_last: lastLoadedId,
          fetchMore: true,
        })
      );
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [likes, handleWindowScroll]);

  const handleSort = (sort: AdSort) => dispatch(setSort(sort));

  return {
    data: ads.list,
    status: ads.status,
    sort: requestArgs.sort,
    handleSort,
  };
};

export default useAdsData;

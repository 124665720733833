import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  editAd,
  getAdDetails,
  selectAdDetailsData,
  selectAdDetailsStatus,
} from '../../features/ads/adSlice';
import { AddAdArgs, EditAdArgs } from '../../features/ads/types';
import Preloader from '../../features/common/Preloader';
import AdForm from './AdForm';

const EditAdFormContainer: FC = () => {
  const { adId } = useParams();
  const dispatch = useAppDispatch();
  const id = adId ? Number(adId) : 0;
  const adDetails = useAppSelector(selectAdDetailsData);
  const adDetailsStatus = useAppSelector(selectAdDetailsStatus);

  const handleSubmit = (values: Partial<EditAdArgs | AddAdArgs>) => {
    debugger;
    dispatch(editAd(values as EditAdArgs));
  };

  useEffect(() => {
    if (!adId) {
      return;
    }
    dispatch(getAdDetails({ slug: id.toString() }));
  }, [adId, dispatch, id]);

  if (adDetailsStatus === 'loading') {
    return <Preloader />;
  }

  const imgs = adDetails?.imgs || [];
  const categoryID = adDetails ? adDetails.categories.at(-1) : undefined;

  const initialValues = {
    ...adDetails,
    categoryID,
    imgs,
    img: undefined,
  };

  return adDetails ? (
    <AdForm id={id} initialValues={initialValues} onSubmit={handleSubmit} />
  ) : null;
};

export default EditAdFormContainer;

import { FC } from 'react';
import Preloader from '../../features/common/Preloader';
import { ID } from '../../features/common/types';
import { Filter } from '../../features/filters/types';
import Widget from '../../features/filters/Widget';

interface FilterSectionListProps {
  filters: Filter[];
  categoryId: ID;
  isLoading: boolean;
}

const FilterSectionList: FC<FilterSectionListProps> = ({
  filters,
  categoryId,
  isLoading,
}) => {
  if (isLoading) return <Preloader />;

  return (
    <>
      {filters.map((filter) => (
        <Widget key={filter.title} filter={filter} categoryId={categoryId} />
      ))}
    </>
  );
};

export default FilterSectionList;

import { FC } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from '../../app/hooks';
import {
  selectCategoriesStatus,
  selectCategoryById,
  selectSubcategories,
} from '../../features/categories/categoriesSlice';
import { Category as CategoryType } from '../../features/categories/types';
import { LoadingStatus } from '../../features/common/types';
import {
  selectFilters,
  selectFiltersLoadingStatus,
} from '../../features/filters/filtersSlice';
import { Filters } from '../../features/filters/types';
import useCategoryId from '../../features/categories/useCategoryId';
import Category from './Category';
import NotFound from '../../features/NotFound';
import Preloader from '../../features/common/Preloader';

const CategoryContainer: FC = () => {
  const categoryId = useCategoryId() || 0;

  const subcategories = useAppSelector<CategoryType[]>((state) =>
    selectSubcategories(state, categoryId)
  );
  const filters = useAppSelector<Filters>((state) =>
    selectFilters(state, categoryId)
  );
  const filtersLoadingStatus = useAppSelector<LoadingStatus>(
    selectFiltersLoadingStatus
  );

  const category = useAppSelector<CategoryType | null>((state) =>
    selectCategoryById(state, categoryId)
  );

  const categoriesStatus = useAppSelector<LoadingStatus>(
    selectCategoriesStatus
  );

  const isFiltersLoading =
    filtersLoadingStatus === 'loading' && isEmpty(filters);

  if (categoriesStatus === 'loading' || categoriesStatus === 'idle') {
    return <Preloader />;
  }

  if (!category) {
    return <NotFound />;
  }

  return (
    <Category
      category={category}
      subcategories={subcategories}
      filters={filters}
      isFiltersLoading={isFiltersLoading}
    />
  );
};

export default CategoryContainer;

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Category } from '../../types';
import { getImgUrl } from '../../../../utils/api';
import styles from './Categories.module.scss';
import { getCategoryPath } from './utils';

interface CategoryCardProps {
  category: Category;
}

const CategoryCard: FC<CategoryCardProps> = ({ category }) => {
  const { title, img } = category;

  const imgSrc = img
    ? getImgUrl(img)
    : process.env.PUBLIC_URL + '/picture_not_available_400-300.png';

  return (
    <Link to={getCategoryPath(category)}>
      <div className={styles.linkWrap}>
        <img src={imgSrc} className={styles.img} alt={title} />
        <div className={styles.text}>{title}</div>
      </div>
    </Link>
  );
};

export default CategoryCard;

import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Alert from '../../features/common/Alert';
import Preloader from '../../features/common/Preloader';
import { getMyAds, selectMyAdsState } from '../../features/myAds/myAdsSlice';
import MyAds from './MyAds';

const MyAdsContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { list, status } = useAppSelector(selectMyAdsState);

  useEffect(() => {
    dispatch(getMyAds());
  }, [dispatch]);

  if (status === 'loading') {
    return <Preloader />;
  }

  if (status === 'failed') {
    return (
      <Alert>
        <FormattedMessage
          id="error.something-went-wrong"
          defaultMessage="Что-то пошло не так"
        />
      </Alert>
    );
  }

  return <MyAds list={list} />;
};

export default MyAdsContainer;

import { FC } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
  selectRootCategories,
  selectSubcategories,
} from '../../../features/categories/categoriesSlice';
import { Category } from '../../../features/categories/types';
import { ID } from '../../../features/common/types';
import CategorySelectorItem from './CategorySelectorItem';

interface CategorySelectorItemContainerProps {
  categoryId: ID;
  onChange: (value: ID | null) => void;
}

const CategorySelectorItemContainer: FC<CategorySelectorItemContainerProps> = ({
  categoryId,
  onChange,
}) => {
  const categories = useAppSelector<Category[]>((state) =>
    selectSubcategories(state, categoryId)
  );
  const rootCategories = useAppSelector<Category[]>(selectRootCategories);

  return (
    <CategorySelectorItem
      categories={categoryId ? categories : rootCategories}
      onChange={onChange}
    />
  );
};

export default CategorySelectorItemContainer;

import {
  createSlice,
  createAsyncThunk,
  Selector,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import api from '../../utils/api';
import { ID, LoadingStatus } from '../common/types';
import { Category } from './types';

export interface CategoriesState {
  data: Category[];
  status: LoadingStatus;
}

const initialState: CategoriesState = {
  data: [],
  status: 'idle',
};

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async () => {
    const response = await api.get('/categories');
    return response.data;
  }
);

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.status = 'success';
    });

    builder.addCase(getCategories.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { reset } = categoriesSlice.actions;

export const selectCategories: Selector<RootState, Category[]> = (state) =>
  state.categories.data;

export const selectCategoriesLoadingStatus: Selector<
  RootState,
  LoadingStatus
> = (state) => state.categories.status;

type RootCategory = Omit<Category, 'parentID'>;

export const selectCategoriesData: Selector<RootState, Category[]> = (state) =>
  state.categories.data;

export const selectRootCategories: Selector<RootState, RootCategory[]> =
  createSelector(selectCategories, (categories) =>
    categories.filter((category) => !category.parentID)
  );

export const selectCategoryById: Selector<RootState, Category | null, [ID]> = (
  state,
  id
) => state.categories.data.find((category) => category.ID === id) || null;

export const selectSubcategories = (
  state: RootState,
  categoryId: number
): Category[] =>
  state.categories.data.filter((category) => category.parentID === categoryId);

export const selectCategoriesStatus: Selector<RootState, LoadingStatus> = (
  state
) => state.categories.status;

export default categoriesSlice.reducer;

import { FC } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectCurrency } from '../../currency/currencySlice';
import { Currency } from '../../currency/types';
import { AdListItem } from '../types';
import AdCard from './AdCard';

interface AdCardContainerProps {
  ad: AdListItem;
}

const AdCardContainer: FC<AdCardContainerProps> = ({ ad }) => {
  const currency = useAppSelector<Currency | null>(selectCurrency);

  return <AdCard ad={ad} currencyCode={currency?.code || null} />;
};

export default AdCardContainer;

import { ChangeEventHandler, FC } from 'react';
import { CategoryProperty } from '../../../features/categories/types';
import Dropdown from '../../../features/common/Dropdown';
import { MenuOption } from '../../../features/common/types';
import styles from './PropertyValueSelector.module.scss';

interface PropertyValueSelectorProps {
  propertyValues?: CategoryProperty[];
  value: string;
  onChange: (value: string) => void;
  onOpen: () => void;
  isLoading: boolean;
}

const PropertyValueSelector: FC<PropertyValueSelectorProps> = ({
  propertyValues,
  value,
  isLoading,
  onChange,
  onOpen,
}) => {
  const options: MenuOption[] =
    propertyValues?.map(({ ID, title }) => ({
      value: (ID || title).toString(),
      label: title,
    })) || [];

  const handleTextFieldChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
  };

  const renderDropdownLabel = (label?: string) => {
    return (
      <input
        className={styles.input}
        value={label || value}
        onChange={handleTextFieldChange}
      />
    );
  };

  return (
    <Dropdown
      labelClassName={styles.dropdownLabel}
      options={options}
      value={value}
      onChange={onChange}
      onOpen={onOpen}
      isLoading={isLoading}
      renderLabel={renderDropdownLabel}
    />
  );
};

export default PropertyValueSelector;

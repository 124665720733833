import { FC, ReactElement } from 'react';
import { ID } from '../../common/types';
import { useAdsData } from '../hooks';
import { AdsData } from '../types';

type AdsGridRenderer = (data: AdsData) => ReactElement<any, any> | null;

interface AdsProps {
  categoryId: ID;
  children: AdsGridRenderer;
}

const AdsContainer: FC<AdsProps> = ({ categoryId, children }) => {
  const { status, data, sort, handleSort } = useAdsData(categoryId);

  return children({ status, data, sort, handleSort });
};

export default AdsContainer;

import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { createAd } from '../../features/ads/adSlice';
import { AddAdArgs, EditAdArgs } from '../../features/ads/types';
import { selectCurrencyId } from '../../features/currency/currencySlice';
import { selectLangId } from '../../features/lang/langSlice';
import AdForm from './AdForm';

const AddAdFormContainer: FC = () => {
  const { adId } = useParams();
  const dispatch = useAppDispatch();
  const currency = useAppSelector(selectCurrencyId);
  const lang = useAppSelector(selectLangId);
  const isInitValuesReady = currency && lang;
  const id = adId ? Number(adId) : 0;

  const handleSubmit = (values: Partial<AddAdArgs | EditAdArgs>) => {
    dispatch(createAd(values as AddAdArgs));
  };

  useEffect(() => {
    if (!adId) {
      return;
    }
  }, [adId]);

  const initialValues = {
    title: '',
    body: '',
    imgs: [],
    properties: [],
    currency,
    price: 0,
    lang,
  };

  return isInitValuesReady ? (
    <AdForm id={id} initialValues={initialValues} onSubmit={handleSubmit} />
  ) : null;
};

export default AddAdFormContainer;

import { Skeleton } from '@mui/material';

const CardsPlaceholder = () => (
  <div className="card">
    <Skeleton variant="rectangular" className="card-image" height={200} />
    <div className="card-wrap">
      <div className="card-title">
        <Skeleton variant="text" />
        <Skeleton variant="text" width="40%" />
      </div>
      <div className="card-price-wrap">
        <div style={{ width: '100%' }}>
          <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={90} />
        </div>
        <div className="card-arrow">
          <Skeleton variant="rounded" height={20} width={20} />
        </div>
      </div>
    </div>
  </div>
);

export default CardsPlaceholder;

import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { logout, selectIsAuth } from '../userSlice';
import AuthFormModal from './AuthFormModal';
import RegistryFormModal from './RegistryFormModal';
import styles from './styles.module.scss';
import UserMenu from './UserMenu';

const Auth: FC = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const isAuth = useAppSelector<boolean>(selectIsAuth);
  const isAuthOpen = hash === '#auth';
  const isRegistrationOpen = hash === '#registration';

  const handleSubmit = () => {
    alert('submit');
  };

  const handleClose = () => {
    navigate({ hash: '' });
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.reload();
  };

  useEffect(() => {
    if (isAuth) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <>
      {isAuth ? (
        <UserMenu onLogout={handleLogout} />
      ) : (
        <Link className="login_button" to="#auth">
          <IoPersonCircleOutline className={styles.personIcon} />
          <span>
            <FormattedMessage id="header.enter" defaultMessage="Войти" />
          </span>
        </Link>
      )}
      {isAuthOpen && (
        <AuthFormModal
          show={isAuthOpen}
          onSubmit={handleSubmit}
          onHide={handleClose}
        />
      )}
      {isRegistrationOpen && (
        <RegistryFormModal show={isRegistrationOpen} onHide={handleClose} />
      )}
    </>
  );
};

export default Auth;

import { FC } from 'react';
import { FaImage } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AdListItem } from '../../features/ads/types';
import styles from './MyAds.module.scss';

interface MyAdsProps {
  list: AdListItem[];
}

const MyAds: FC<MyAdsProps> = ({ list }) => {
  return (
    <div className="container">
      <h1>
        <FormattedMessage id="header.my_ads" defaultMessage="Мои объявления" />
      </h1>

      {list.map((item) => (
        <div key={item.ID} className={styles.item}>
          <div className={styles.imageWrap}>
            {item.img_url ? (
              <img width="64px" src={`//${item.img_url}`} alt={item.title} />
            ) : (
              <FaImage
                style={{ width: '64px', height: '64px', fill: 'gray' }}
              />
            )}
          </div>
          <div>
            <Link to={`/edit/${item.ID}`}>{item.title}</Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyAds;

import { FC, useState } from 'react';
import { PropertyItem } from '../../../features/ads/types';
import { CategoryProperty } from '../../../features/categories/types';
import { ID, LoadingStatus } from '../../../features/common/types';
import api from '../../../utils/api';
import PropertyValueSelector from './PropertyValueSelector';

interface PropertyValueSelectorContainerProps {
  propertyId: ID;
  value: ID | string;
  onChange: (value: PropertyItem) => void;
}

const PropertyValueSelectorContainer: FC<
  PropertyValueSelectorContainerProps
> = ({ propertyId, value, onChange }) => {
  const [propertyValues, setPropertyValues] = useState<
    CategoryProperty[] | undefined
  >();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('idle');

  const handleOpen = () => {
    if (loadingStatus !== 'idle') {
      return;
    }

    setLoadingStatus('loading');
    api
      .patch(`filters_only/${propertyId}`)
      .then(({ data }) => {
        setPropertyValues(data);
        setLoadingStatus('success');
      })
      .catch(() => {
        setLoadingStatus('failed');
      });
  };

  const handleChange = (value: ID | string) => {
    onChange({ ID: propertyId, value });
  };

  return (
    <PropertyValueSelector
      propertyValues={propertyValues}
      value={value.toString()}
      onChange={handleChange}
      onOpen={handleOpen}
      isLoading={loadingStatus === 'loading'}
    />
  );
};

export default PropertyValueSelectorContainer;

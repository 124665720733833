import { FC } from 'react';
import Spinner from './Spinner';

const Preloader: FC = () => {
  return (
    <div className="center padding_10">
      <Spinner />
    </div>
  );
};

export default Preloader;

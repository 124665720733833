import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import Categories from '../../features/categories/components/Categories';
import useSearch from '../../features/search/hooks/useSearch';
import HomeAds from './HomeAds';

const Home: FC = () => {
  const [searchString] = useSearch();
  const intl = useIntl();
  const title = searchString
    ? intl.formatMessage(
        {
          id: 'home.title.search_result',
          defaultMessage: 'Результаты по запросу {searchString}',
        },
        { searchString }
      )
    : intl.formatMessage({
        id: 'home.title.all_ads',
        defaultMessage: 'Все объявления',
      });

  const pageTitle = intl.formatMessage({
    id: 'home.head.title',
    defaultMessage: 'Крупнейший маркетплейс Северного Кипра',
  });

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="padding_10"></div>
      <Categories />
      <HomeAds title={title} />
    </div>
  );
};

export default Home;

import { FC } from 'react';
import { FaTrash } from 'react-icons/fa';
import { ID } from '../../../features/common/types';
import { getSmallImgUrl } from '../../../utils/api';
import styles from './ImageManager.module.scss';

interface ImageManagerItemProps {
  imageId: ID;
  onDelete: (id: ID) => void;
}

const ImageManagerItem: FC<ImageManagerItemProps> = ({ imageId, onDelete }) => {
  const handleDelete = () => {
    onDelete(imageId);
  };
  return (
    <div className={styles.item}>
      <img src={getSmallImgUrl(imageId)} alt={imageId.toString()} />
      <FaTrash onClick={handleDelete} />
    </div>
  );
};

export default ImageManagerItem;

import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PropertyItem } from '../../../features/ads/types';
import {
  getCategoryDetails,
  reset as resetCategoryDetails,
  selectCategoryDetails,
  selectCategoryDetailsStatus,
} from '../../../features/categories/categoryDetailsSlice';
import { CategoryDetails } from '../../../features/categories/types';
import Preloader from '../../../features/common/Preloader';
import { ID, LoadingStatus } from '../../../features/common/types';
import PropertiesSelector from './PropertiesSelector';

interface PropertiesSelectorContainerProps {
  categoryId: ID | null;
  value: PropertyItem[];
  onChange: (value: PropertyItem[]) => void;
}

const PropertiesSelectorContainer: FC<PropertiesSelectorContainerProps> = ({
  categoryId,
  value,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const category = useAppSelector<CategoryDetails | null>(
    selectCategoryDetails
  );
  const status = useAppSelector<LoadingStatus>(selectCategoryDetailsStatus);

  useEffect(() => {
    if (categoryId) {
      dispatch(getCategoryDetails(categoryId));
    }

    return () => {
      dispatch(resetCategoryDetails());
    };
  }, [categoryId, dispatch]);

  if (status === 'loading') {
    return <Preloader />;
  }

  return (
    category && (
      <PropertiesSelector
        value={value}
        onChange={onChange}
        properties={category.properties}
      />
    )
  );
};

export default PropertiesSelectorContainer;

import { FC } from 'react';
import Checkbox from '../../common/Checkbox';
import { FilterOption } from '../types';
import styles from './Widget.module.scss';

interface CheckboxListProps {
  options: FilterOption[];
  value: FilterOption[];
  onChange: (value: FilterOption[]) => void;
}

const isFound = (option: FilterOption, options: FilterOption[]) =>
  options.filter(({ title }) => title === option.title).length > 0;

const CheckboxList: FC<CheckboxListProps> = ({ options, value, onChange }) => {
  const handleChange = (option: FilterOption) => {
    if (isFound(option, value)) {
      onChange(value.filter(({ title }) => title !== option.title));
    } else {
      onChange([...value, option]);
    }
  };

  return (
    <ul className={styles.checkboxList}>
      {options.map((option, index) => {
        return (
          <li key={index}>
            <Checkbox
              id={option.title}
              checked={isFound(option, value)}
              label={option.title}
              disabled={Boolean(option.disabled)}
              onChange={() => handleChange(option)}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default CheckboxList;

import { FC } from 'react';
import { AdListItem } from '../types';
import { getSmallImgUrl } from '../../../utils/api';
import FormattedPrice from '../../currency/components/FormattedPrice';

interface AdCardProps {
  ad: AdListItem;
  currencyCode: string | null;
}

const AdCard: FC<AdCardProps> = ({ ad, currencyCode }) => {
  const { title, price, slug } = ad;

  const imgSrc: string = ad.img_url
    ? `https://${ad.img_url}`
    : getSmallImgUrl(ad.img);

  const link = `/${slug}`;

  return (
    <a target="_blank" rel="noreferrer" href={link}>
      <div className="card">
        <div
          data-testid="card-image"
          className="card-image"
          style={{ background: `url(${imgSrc}) no-repeat center` }}
        ></div>
        <div className="card-wrap">
          <div className="card-title">{title}</div>
          <div className="card-price-wrap">
            {currencyCode && (
              <FormattedPrice
                value={price}
                currencyCode={currencyCode}
                className="card-price"
                noPriceClassName="card-no-price"
              />
            )}
            <div className="card-arrow">
              <img alt="->" src="/img/arrow-forward.svg" />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default AdCard;

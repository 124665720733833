import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { ID } from '../common/types';
import { selectCategories } from './categoriesSlice';
import { Category } from './types';

const useCategoryId = (): ID | null => {
  const { categorySlug } = useParams();
  const categories = useAppSelector<Category[]>(selectCategories);

  const category = categories.find((item) => item.slug === categorySlug);

  return category?.ID || null;
};

export default useCategoryId;

import { createAsyncThunk, createSlice, Selector } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Lang, Locale } from './types';
import api from '../../utils/api';
import { ID, LoadingStatus, MenuOption } from '../common/types';
import { localeIdStorageKey, locales, RU_LANG_ID } from './constants';
import languageIcons from './languageIcons';

export interface LangState {
  status: LoadingStatus;
  data: Lang[];
  current: ID;
  locale: Locale;
}

const initialState: LangState = {
  status: 'idle',
  data: [],
  current: Number(localStorage.getItem(localeIdStorageKey) || RU_LANG_ID),
  locale: 'ru',
};

const localeList = [locales.ru, locales.ru, locales.en, locales.tr];

export const getLanguages = createAsyncThunk<Lang[], void>(
  'lang/getLanguages',
  async () => {
    const response = await api.get('/langs');
    return response.data;
  }
);

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLocale: (state, { payload }) => {
      state.locale = payload;
    },
    setLangId: (state, { payload }) => {
      state.current = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLanguages.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getLanguages.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.status = 'success';
    });

    builder.addCase(getLanguages.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { setLocale, setLangId } = langSlice.actions;

export const selectLangId: Selector<RootState, ID> = (state) =>
  state.lang.current;

export const selectLocale: Selector<RootState, Locale> = (state) =>
  localeList[state.lang.current];

export const selectLanguageOptions: Selector<RootState, MenuOption[]> = (
  state
) =>
  state.lang.data.map((locale) => ({
    value: locale.ID.toString(),
    label: locale.title,
    icon: languageIcons[locale.ID],
  }));

export default langSlice.reducer;

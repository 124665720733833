import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';
import { MenuOption, MenuPosition } from './types';
import styles from './Dropdown.module.scss';
import Menu from './Menu';
import DropdownArrow from './DropDownArrow';
import Spinner from './Spinner';

interface DropdownProps {
  options: MenuOption[];
  value: string;
  menuPosition?: MenuPosition;
  onChange: (value: string) => void;
  className?: string;
  labelClassName?: string;
  onOpen?: () => void;
  isLoading?: boolean;
  renderLabel?: (label?: string) => ReactNode;
}

const Dropdown: FC<DropdownProps> = ({
  options,
  value,
  onChange,
  menuPosition = 'left',
  className,
  labelClassName,
  onOpen,
  isLoading,
  renderLabel,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (open) {
      return;
    }
    setOpen(true);
    onOpen?.();
  };

  const handleClose = () => setOpen(false);

  const handleChange = (value: string) => {
    onChange(value);
    handleClose();
  };

  const currentOption: MenuOption | undefined = options.find(
    (option) => option.value === value
  );

  const filteredOptions = options.filter((option) => option.value !== value);

  return (
    <div
      role="listbox"
      aria-expanded={open}
      className={cn(styles.root, className)}
      onClick={handleOpen}
    >
      <div className={cn(styles.inputBox, labelClassName)}>
        {currentOption?.icon && <div>{currentOption?.icon}</div>}
        {typeof renderLabel === 'function' ? (
          renderLabel(currentOption?.label)
        ) : (
          <span>{currentOption?.label}</span>
        )}
        {isLoading ? <Spinner /> : <DropdownArrow isOpen={open} />}
      </div>

      <Menu
        selectedValue={value}
        open={open}
        options={filteredOptions}
        onSelect={handleChange}
        onClose={handleClose}
        position={menuPosition}
        className={styles.menu}
      />
    </div>
  );
};

export default Dropdown;

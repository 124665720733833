import { FC } from 'react';
import { Category } from '../../features/categories/types';
import useCategoryId from '../../features/categories/useCategoryId';
import { Filter as FilterType } from '../../features/filters/types';
import FilterSectionList from './FilterSectionList';
import SubcategoryList from './SubcategoryList';
import styles from './Filter.module.scss';

interface FilterProps {
  subcategories: Category[];
  filters: FilterType[];
  isLoading: boolean;
}

const Filter: FC<FilterProps> = ({ subcategories, filters, isLoading }) => {
  const categoryId = useCategoryId();

  if (!categoryId) return null;

  return (
    <div className={styles.wrap}>
      <SubcategoryList list={subcategories} />
      <FilterSectionList
        categoryId={categoryId}
        filters={filters}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Filter;

import { FC } from 'react';
import { useAppSelector } from '../../app/hooks';
import Dropdown from '../../features/common/Dropdown';
import { ID } from '../../features/common/types';
import { selectCurrencyOptions } from '../../features/currency/currencySlice';
import styles from './AdForm.module.scss';

interface CurrencyDropdownContainerProps {
  value: ID;
  onChange: (value: ID) => void;
}

const CurrencyDropdownContainer: FC<CurrencyDropdownContainerProps> = ({
  value,
  onChange,
}) => {
  const options = useAppSelector(selectCurrencyOptions);

  const handleChange = (value: string) => {
    onChange(Number(value));
  };

  return (
    <Dropdown
      className={styles.priceDropdown}
      labelClassName={styles.dropdownLabel}
      options={options}
      value={value.toString()}
      onChange={handleChange}
    />
  );
};

export default CurrencyDropdownContainer;

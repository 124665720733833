import { ChangeEventHandler, FC } from 'react';

interface CheckboxProps {
  id: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </>
  );
};

export default Checkbox;

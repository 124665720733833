import { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './TextField.module.scss';
import FieldLabel from './FieldLabel';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label?: string;
  isRequired?: boolean;
}

const TextField: FC<TextFieldProps> = ({
  error,
  label,
  isRequired,
  ...props
}) => {
  return (
    <div className={cn(styles.root, { [styles.error]: error })}>
      {label && <FieldLabel isRequired={isRequired}>{label}</FieldLabel>}
      <input {...props} />
      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export default TextField;

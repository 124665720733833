import { createSlice, createAsyncThunk, Selector } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import api from '../../utils/api';
import { ID, LoadingStatus } from '../common/types';
import { CategoryDetails } from './types';

export interface CategoriesState {
  data: CategoryDetails | null;
  status: LoadingStatus;
}

const initialState: CategoriesState = {
  data: null,
  status: 'idle',
};

export const getCategoryDetails = createAsyncThunk(
  'categoryDetails/getCategoryDetails',
  async (categoryId: ID) => {
    const response = await api.get(`/category/${categoryId}`);
    return response.data;
  }
);

export const categoryDetailsSlice = createSlice({
  name: 'categoryDetails',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryDetails.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getCategoryDetails.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.status = 'success';
    });

    builder.addCase(getCategoryDetails.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { reset } = categoryDetailsSlice.actions;

export const selectCategoryDetails: Selector<
  RootState,
  CategoryDetails | null
> = (state) => state.categoryDetails.data;

export const selectCategoryDetailsStatus: Selector<RootState, LoadingStatus> = (
  state
) => state.categoryDetails.status;

export default categoryDetailsSlice.reducer;

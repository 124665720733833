import { createSlice, createAsyncThunk, Selector } from '@reduxjs/toolkit';
import { getAccessToken } from './../../utils/tokens';
import { RootState } from '../../app/store';
import api from '../../utils/api';
import { LoadingStatus } from './../common/types';
import {
  Ad,
  AddAdArgs,
  AdListItem,
  FetchAdListArgs,
  EditAdArgs,
} from './types';

export type AdState = {
  list: AdListItem[];
  details: Ad | null;
  status: LoadingStatus;
  finished: boolean;
  detailsStatus: LoadingStatus;
};

const initialState: AdState = {
  list: [],
  details: null,
  status: 'idle',
  finished: false,
  detailsStatus: 'idle',
};

const fetchDashboards = async (args: FetchAdListArgs) => {
  const response = await api.patch('/dashboards', args);
  return response.data;
};

export const getAds = createAsyncThunk<
  AdListItem[],
  FetchAdListArgs & {
    fetchMore?: boolean;
  }
>('ads/getAds', fetchDashboards);

const fetchAdDetails = async ({ slug }: { slug: string }) => {
  const response = await api.get(`dashboard/${slug}`);
  return response.data;
};

export const getAdDetails = createAsyncThunk<Ad, { slug: string }>(
  'ads/getAdDetails',
  fetchAdDetails
);

export const createAd = createAsyncThunk<any, Partial<AddAdArgs>>(
  'ads/createAd',
  async (args) => {
    const token = await getAccessToken();
    const response = await api.post('/dashboards', args, {
      headers: { key: token },
    });
    window.location.replace('/' + response.data); // navigate to created ad
  }
);

export const editAd = createAsyncThunk<any, Partial<EditAdArgs>>(
  'ads/editAd',
  async (args) => {
    const token = await getAccessToken();
    const response = await api.post(`/dashboard/${args.ID}`, args, {
      headers: { key: token },
    });
    window.location.replace('/' + response.data); // navigate to created ad
  }
);

export const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    resetAds: (state) => {
      state.list = [];
      state.details = null;
      state.status = 'idle';
      state.finished = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAds.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getAds.fulfilled, (state, { payload, meta }) => {
      state.status = 'success';
      state.list = meta.arg.fetchMore ? state.list.concat(payload) : payload;
      if (payload.length === 0) {
        state.finished = true;
      }
    });

    builder.addCase(getAds.rejected, (state) => {
      state.status = 'failed';
    });

    builder.addCase(getAdDetails.pending, (state) => {
      state.detailsStatus = 'loading';
    });

    builder.addCase(getAdDetails.fulfilled, (state, { payload }) => {
      state.details = payload;
      state.detailsStatus = 'success';
    });

    builder.addCase(getAdDetails.rejected, (state) => {
      state.detailsStatus = 'failed';
    });
  },
});

export const { resetAds } = adsSlice.actions;

export const selectAdsState: Selector<RootState, AdState> = (state) =>
  state.ads;

export const selectLastId: Selector<RootState, number> = (state) =>
  state.ads.list[state.ads.list.length - 1]?.ID || 0;

export const selectIsFinished: Selector<RootState, boolean> = (state) =>
  state.ads.finished;

export const selectHasMore: Selector<RootState, boolean> = (state) =>
  !state.ads.finished;

export const selectAdDetailsData: Selector<RootState, Ad | null> = (state) =>
  state.ads.details;

export const selectAdDetailsStatus: Selector<RootState, LoadingStatus> = (
  state
) => state.ads.detailsStatus;

export default adsSlice.reducer;

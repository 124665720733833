import { FC } from 'react';
import { FaUser } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { BsTelephoneFill } from 'react-icons/bs';
import styles from './AuthorContacts.module.scss';

interface AuthorContactsProps {
  name?: string;
  phone?: string;
  email?: string;
}

const AuthorContacts: FC<AuthorContactsProps> = ({ name, phone, email }) => {
  return (
    <div className={styles.root}>
      {name && (
        <div key="name" className={styles.item}>
          <FaUser />
          {name}
        </div>
      )}
      {phone && (
        <div key="phone" className={styles.item}>
          <BsTelephoneFill />

          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      )}
      {email && (
        <div key="email" className={styles.item}>
          <MdEmail />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}
    </div>
  );
};

export default AuthorContacts;

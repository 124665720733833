import api from './api';

const refreshTokenKey = 'refreshToken';

let accessToken: string | null = null;

const isAccessTokenActual = (token: string): boolean => true;

export const getAccessToken = async (): Promise<string> => {
  if (accessToken && isAccessTokenActual(accessToken)) {
    return accessToken;
  }
  const newTokenResponse = await api.post<string>('key', getRefreshToken());
  setAccessToken(newTokenResponse.data);
  return newTokenResponse.data;
};

export const setAccessToken = (token: string) => (accessToken = token);

export const getRefreshToken = (): string => {
  const token = localStorage.getItem(refreshTokenKey);
  if (!token) {
    throw Error('Cannot get refresh token');
  }
  return token;
};

export const setRefreshToken = (token: string) =>
  localStorage.setItem(refreshTokenKey, token);

export const removeRefreshToken = () =>
  localStorage.removeItem(refreshTokenKey);

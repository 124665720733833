import { FC } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface DropdownArrowProps {
  isOpen: boolean;
}

const DropdownArrow: FC<DropdownArrowProps> = ({ isOpen }) => {
  if (isOpen) {
    return <FaCaretUp />;
  }

  return <FaCaretDown />;
};

export default DropdownArrow;

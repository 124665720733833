import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  registerUser,
  reset,
  selectIsUserRegistationFailed,
  selectIsUserRegistring,
} from '../userSlice';
import { hash } from '../../../utils/auth';
import { setRefreshToken } from '../../../utils/tokens';
import { useAppSelector } from '../../../app/hooks';
import { ErrorGetter } from '../types';
import Modal from '../../common/Modal';
import Alert from '../../common/Alert';
import TextField from '../../common/TextField';
import Button from '../../common/Button';
import styles from './styles.module.scss';

interface SignUpFormValues {
  name: string;
  phone?: string;
  mail: string;
  password: string;
  passwordConfirmation: string;
}

interface SignUpFormProps {
  onHide: () => void;
  show: boolean;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  mail: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const RegistryFormModal: FC<SignUpFormProps> = ({ onHide, show }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isRegistration = useAppSelector<boolean>(selectIsUserRegistring);
  const isFailed = useAppSelector<boolean>(selectIsUserRegistationFailed);
  const formik = useFormik<SignUpFormValues>({
    initialValues: {
      name: '',
      mail: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        registerUser({
          data: {
            name: values.name,
            mail: values.mail,
            passwordHex: hash(values.password),
          },
          onSuccess: (token) => {
            setRefreshToken(token);
            handleClose();
          },
        })
      );
    },
  });

  const handleClose = () => {
    formik.resetForm();
    dispatch(reset());
    onHide();
  };

  const getError: ErrorGetter<SignUpFormValues> = (key) => {
    if (formik.touched[key]) {
      return formik.errors[key];
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <div className="login_title">
          <FormattedMessage
            id="auth.sign_up_form_title"
            defaultMessage="Регистрация пользователя"
          />
        </div>
        <div className="login_form">
          {isFailed && (
            <Alert>
              <FormattedMessage
                id="error.sign_up_error"
                defaultMessage="Не удалось создать пользователя"
              />
            </Alert>
          )}
          <TextField
            name="name"
            placeholder={intl.formatMessage({
              id: 'auth.label.user_name',
              defaultMessage: 'Имя пользователя',
            })}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={getError('name')}
          />
          <TextField
            name="mail"
            placeholder={intl.formatMessage({
              id: 'auth.label.email',
              defaultMessage: 'Электронная почта',
            })}
            type="email"
            value={formik.values.mail}
            onChange={formik.handleChange}
            error={getError('mail')}
          />
          <TextField
            name="password"
            type="password"
            placeholder={intl.formatMessage({
              id: 'auth.label.password',
              defaultMessage: 'Пароль',
            })}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={getError('password')}
          />
          <TextField
            name="passwordConfirmation"
            type="password"
            placeholder={intl.formatMessage({
              id: 'auth.label.password_confirm',
              defaultMessage: 'Подтверждение пароля',
            })}
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            error={getError('passwordConfirmation')}
          />
        </div>

        <Button type="submit" disabled={isRegistration}>
          <FormattedMessage
            id="header.sign_up"
            defaultMessage="Зарегистрироваться"
          />
        </Button>

        <div className={styles.registerLinkWrap}>
          <FormattedMessage
            id="auth.do_you_have_an_account"
            defaultMessage="Уже есть учетная запись?"
          />{' '}
          <Link to="#auth">
            <FormattedMessage id="header.enter" defaultMessage="Войти" />
          </Link>
        </div>
      </form>
    </Modal>
  );
};

export default RegistryFormModal;

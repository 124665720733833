import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { CategoryProperty } from '../../../features/categories/types';
import PropertyValueSelectorContainer from './PropertyValueSelectorContainer';
import { PropertyItem } from '../../../features/ads/types';

interface PropertiesSelectorProps {
  properties: CategoryProperty[];
  value: PropertyItem[];
  onChange: (value: PropertyItem[]) => void;
}

const PropertiesSelector: FC<PropertiesSelectorProps> = ({
  properties,
  value,
  onChange,
}) => {
  const handleItemChange = (itemValue: PropertyItem) => {
    const index = value.findIndex((item) => item.ID === itemValue.ID);

    if (index === -1) {
      onChange([...value, itemValue]);
    } else {
      const result = [...value];
      result[index] = itemValue;
      onChange(result.filter((item) => item.value !== ''));
    }
  };

  return (
    <>
      <p>
        <FormattedMessage
          id="create_ad.label.specify_properties"
          defaultMessage="Укажите свойства"
        />
      </p>
      <div className="padding_5"></div>
      <table className="characteristics">
        {properties.map((property) => (
          <>
            <tr key={`${property.ID || property.title}`}>
              <td>{property.title}</td>
              <td>
                <PropertyValueSelectorContainer
                  propertyId={property.ID}
                  value={
                    value.find((item) => item.ID === property.ID)?.value || ''
                  }
                  onChange={handleItemChange}
                />
              </td>
            </tr>
            <div className="padding_5" />
          </>
        ))}
      </table>
    </>
  );
};

export default PropertiesSelector;

import { useSearchParams } from 'react-router-dom';

const searchParamName = 'q';

const useSearch = (): [string, (value: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm: string = searchParams.get(searchParamName) || '';

  const setSearchTerm = (value: string) => {
    setSearchParams({ [searchParamName]: value.trim() });
  };

  return [searchTerm, setSearchTerm];
};

export default useSearch;

import { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../features/common/Button';
import TextField from '../../features/common/TextField';
import { ID } from '../../features/common/types';
import CategorySelector from './CategorySelector/CategorySelector';
import CurrencyDropdownContainer from './CurrencyDropdownContainer';
import PropertiesSelectorContainer from './PropertiesSelector/PropertiesSelectorContainer';
import styles from './AdForm.module.scss';
import { AddAdArgs, EditAdArgs } from '../../features/ads/types';
import TextAreaField from '../../features/common/TextAreaField';
import FieldLabel from '../../features/common/FieldLabel';
import ImageManager from './ImageManager';

interface AddItemFormProps {
  id?: ID;
  initialValues: Partial<AddAdArgs>;
  onSubmit: (values: Partial<AddAdArgs | EditAdArgs>) => void;
}

const AdForm: FC<AddItemFormProps> = ({ id, initialValues, onSubmit }) => {
  const intl = useIntl();

  const isEditing = Boolean(id);

  const validationRequiredMessage = intl.formatMessage({
    id: 'validation.required',
    defaultMessage: 'Обязательное поле',
  });

  const validationSchema = Yup.object().shape({
    categoryID: Yup.string().required(validationRequiredMessage),
    title: Yup.string().required(validationRequiredMessage),
    body: Yup.string().required(validationRequiredMessage),
    price: Yup.number().typeError(
      intl.formatMessage({
        id: 'validation.price_must_be_a_number',
        defaultMessage: 'Цена должна быть числом',
      })
    ),
  });

  const formik = useFormik<Partial<AddAdArgs>>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="padding_10"></div>
      <div className="container">
        <div className="row">
          <div className="col-12 center">
            <h2>
              {isEditing ? (
                <FormattedMessage
                  id="edit_ad.title"
                  defaultMessage="Редактирование объявления"
                />
              ) : (
                <FormattedMessage
                  id="create_ad.title"
                  defaultMessage="Создание объявления"
                />
              )}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="center">
            <div className="padding_10"></div>
            {!id && (
              <CategorySelector
                onChange={(value) => {
                  formik.setFieldValue('categoryID', value);
                }}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="col-6">
              <TextField
                label={intl.formatMessage({
                  id: 'create_ad.label.enter_ad_tittle',
                  defaultMessage: 'Введите название объявления',
                })}
                className="input_create_ad"
                name="title"
                placeholder={intl.formatMessage({
                  id: 'create_ad.label.ad_tittle',
                  defaultMessage: 'Hазвание объявления',
                })}
                onChange={formik.handleChange}
                value={formik.values.title}
                error={formik.errors.title}
                isRequired
              />
              <TextAreaField
                label={intl.formatMessage({
                  id: 'create_ad.label.enter_ad_description',
                  defaultMessage: 'Введите описание объявления',
                })}
                isRequired
                className={styles.textarea}
                name="body"
                onChange={formik.handleChange}
                value={formik.values.body}
                placeholder={intl.formatMessage({
                  id: 'create_ad.label.ad_description',
                  defaultMessage: 'Описание объявления',
                })}
                error={formik.errors.body}
              />
              <FieldLabel isRequired>
                <FormattedMessage
                  id="create_ad.label.specify_price"
                  defaultMessage="Укажите цену"
                />
              </FieldLabel>
              <div className={styles.flexWrap}>
                <TextField
                  name="price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  placeholder={intl.formatMessage({
                    id: 'create_ad.label.price',
                    defaultMessage: 'Цена',
                  })}
                  error={formik.errors.price}
                />
                {formik.values.currency && (
                  <CurrencyDropdownContainer
                    value={formik.values.currency}
                    onChange={(value) => {
                      formik.setFieldValue('currency', value);
                    }}
                  />
                )}
              </div>

              {formik.values.properties && (
                <PropertiesSelectorContainer
                  value={formik.values.properties}
                  onChange={(value) => {
                    formik.setFieldValue('properties', value);
                  }}
                  categoryId={formik.values.categoryID || null}
                />
              )}
            </div>
            <div className="col-6 center">
              <div className="padding_10 mobile_only"></div>
              <FieldLabel>
                <FormattedMessage
                  id="create_ad.label.upload_photos"
                  defaultMessage="Загрузите фотографии"
                />
              </FieldLabel>
              {formik.values.imgs && (
                <ImageManager
                  images={formik.values.imgs}
                  onChange={(images) => {
                    formik.setFieldValue('imgs', images);
                    formik.setFieldValue('img', images[0]);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="padding_10"></div>
        <div className="row">
          <div className="col-12 center">
            <Button type="submit">
              <FormattedMessage
                id="label.post_ad"
                defaultMessage="Разместить объявление"
              />
            </Button>
          </div>
        </div>
        <div className="padding_10"></div>
      </div>
    </form>
  );
};

export default AdForm;

import {
  createSlice,
  createAsyncThunk,
  Selector,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { RootState } from '../../app/store';
import api from '../../utils/api';
import { AdSort } from '../ads/types';
import { ID, LoadingStatus } from '../common/types';
import {
  Filters,
  FetchFiltersArgs,
  FiltersState,
  FilterOption,
  FilterItem,
  RequestProperty,
  SetFilterArgs,
} from './types';
import { updateFilters } from './utils';

const initialState: FiltersState = {
  data: {},
  status: 'idle',
  values: {},
  sort: AdSort.Default,
};

const fetchFilters = async (args: FetchFiltersArgs) => {
  const response = await api.patch('/filters', args);
  return response.data;
};

export const getFilters = createAsyncThunk<Filters, FetchFiltersArgs>(
  'filters/getFilters',
  fetchFilters
);

const isFilterSet = (filter: FilterItem[] = [], value: FilterItem): boolean =>
  filter.findIndex((item) => item.ID === value.ID) !== -1;

const removeValue = (filter: FilterItem[], value: FilterItem): FilterItem[] =>
  filter.filter((item) => item.ID !== value.ID);

const insertValue = (filter: FilterItem[], value: FilterItem): FilterItem[] =>
  filter.map((item) => (item.ID === value.ID ? value : item));

export const adsSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter: (state, { payload }: PayloadAction<SetFilterArgs>) => {
      if (isFilterSet(state.values[payload.categoryId], payload.filterItem)) {
        if (isEmpty(payload.filterItem.values)) {
          state.values[payload.categoryId] = removeValue(
            state.values[payload.categoryId],
            payload.filterItem
          );
        } else {
          state.values[payload.categoryId] = insertValue(
            state.values[payload.categoryId],
            payload.filterItem
          );
        }
      } else {
        const values = state.values[payload.categoryId] || [];
        state.values[payload.categoryId] = [...values, payload.filterItem];
      }
    },

    setSort: (state, { payload }: PayloadAction<AdSort>) => {
      state.sort = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilters.pending, (state) => {
      state.status = 'loading';
    });

    builder.addCase(getFilters.fulfilled, (state, { payload, meta }) => {
      const [categoryId = 0] = meta.arg.categories;
      state.status = 'success';
      state.data = {
        [categoryId]: state.data[categoryId]
          ? updateFilters(state.data[categoryId], payload)
          : payload,
      };
    });

    builder.addCase(getFilters.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { setFilter, setSort } = adsSlice.actions;

export const selectFilters: Selector<RootState, Filters, [number]> = (
  state,
  categoryId
) => state.filters.data[categoryId] || [];

export const selectFiltersLoadingStatus: Selector<RootState, LoadingStatus> = (
  state
) => state.filters.status;

export const selectFilterPropertyValue: Selector<
  RootState,
  FilterOption[],
  [ID, ID]
> = (state, categoryId, propertyId) =>
  state.filters.values[categoryId]?.find((item) => item.ID === propertyId)
    ?.values || [];

export const selectFilterValues: Selector<
  RootState,
  { [categoryId: ID]: FilterItem[] }
> = (state) => state.filters.values;

export const selectRequestProperties: Selector<
  RootState,
  RequestProperty[],
  [ID]
> = createSelector(
  selectFilterValues,
  (_: RootState, categoryId: ID) => categoryId,
  (values, categoryId) => {
    return values[categoryId]?.map(({ ID, values }) => ({
      ID,
      values: values.map((item) =>
        Object.assign(
          {},
          { ID: item.ID || 0 },
          !item.ID ? { value: item.title } : null
        )
      ),
    }));
  }
);

export const selectSort: Selector<RootState, AdSort> = (state) =>
  state.filters.sort;

export default adsSlice.reducer;
